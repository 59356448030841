import styled from "styled-components"
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from "react-router-dom"
import {useState, useEffect} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import RegisterClient from "./RegisterClient";
import DetailsPage from "./DetailsPage";

export default function ClientPage() {
    const [tabValue, setTabValue] = useState(0)
   const nav = useNavigate();

    const {pathname} = useLocation()
 
    useEffect(()=>{
        setTabValue(tabValuesByPathname[pathname] || 0)
    }, [pathname])

  
    const tabValuesByPathname = {
        "/clientes/criar": 0,
        "/clientes/verificar": 1
    }

    return (
        
        <Container>
            <Tabs value={tabValue}  aria-label="disabled tabs example">
                <Tab label="Criar Conta" onClick={() => nav("/clientes/criar")}/>
                <Tab label="Verificar Conta" onClick={() => nav("/clientes/verificar")}/>   
            </Tabs>
            <hr/>
            
            <Routes>
                <Route path="/criar" element={<RegisterClient/>} />
                <Route path="/verificar" element={<DetailsPage/>} />
            </Routes>
        </Container>
    )
}

const Container = styled.div`
    [role="tablist"]{
        display: flex;
        justify-content: center;
        
        padding-top: 1rem;

        button:first-child{
            margin-right: 1rem;
        }

        button{
            width: 50%;
            font-weight: bold;
            font-size: 1rem;
        }

        button:hover{
             
            background-color: rgba(150, 150, 150, .1);;
        }
    }

    hr{
        margin: 1rem;
    }
`
 