import { style } from "@mui/system";
import axios from "axios";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import warningMessage from "../../../helpers/warnMessage";

import FinanceDetailsHeader from "./details";
import DetailsTable from "./details/DetailsTable";
 
import getFinancePdf from "../../../helpers/getFinancePdf.js";

import {VscFilePdf as PdfIcon } from "react-icons/vsc"
import {TbFileDownload as DownloadIcon } from "react-icons/tb"
import {IoArrowBackCircleOutline as GoBackIcon } from "react-icons/io5"

import { useNavigate } from 'react-router-dom';
import { AuthContext } from "../../../context/AuthContext";
import Swal from "sweetalert2";
import Loader from "../../../Components/SideMenu/Loader";


export default function FinanceDetails() {
    const [finance, setFinance] = useState(null)
    const { id } = useParams();
    const {user} = useContext(AuthContext);
    const [isFinanceLoading, setIsFinanceLoading] = useState(true)

    const navigate = useNavigate();

    useEffect(() => { getFinanceById() }, [])
 
    //finance && SaveFinancePdf(finance)
    
    const transactions = finance?.transactions?.map(f => { return { timestamp:  dayjs(f.createdAt).format("DD/MM/YY - HH:mm") , category: f.category, type: f.type, description: f.annotations, amount: 1, moneyValue: f.moneyValue, pixValue: f.pixValue, cardValue: f.cardValue, totalValue: f.totalValue } })

    return (
        <Container>
            <div>
                <PdfIcon onClick={()=> handleGetFinanceDetailsPdf(false)}/>
                <DownloadIcon onClick={()=> handleGetFinanceDetailsPdf(true)}/>
            </div>
            { finance && <FinanceDetailsHeader initialMoney={finance.initialMoney} finalMoney={finance.finalMoney} startDate={finance.startDate} endDate={finance.endDate} annotations={finance.annotations} />}
            {isFinanceLoading ? <LoaderContainer>  <Loader width={"8rem"} height="8rem" /> </LoaderContainer>: <DetailsTable transactions={transactions} />}
            <div onClick={() => navigate(-1)}><GoBackIcon/></div>
        </Container>
    )

    function handleGetFinanceDetailsPdf(shouldDownload){
        Swal.fire({
            title: 'Detalhar produtos em cada pedido?',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            denyButtonText: `Não`,
            cancelButtonText: "Voltar"
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                getFinancePdf([finance], finance.startDate, finance.endDate, shouldDownload, true)
            } else if (result.isDenied) {
                getFinancePdf([finance], finance.startDate, finance.endDate, shouldDownload, false)
            }
        })
    }

    function getFinanceById() {
        const promise = axios.get(`https://${user.ip}/api/finance/${id}`, {headers:{Authorization: `Bearer ${user.token}`}})
        promise.then(res => {
            setFinance(res.data);
            setIsFinanceLoading(false)
        })
            .catch(err => {
                if (err?.response?.status === 401)
                     warningMessage("Acesso não autorizado")

                if (err?.response?.status !== 404)
                     warningMessage("Erro inesperado")

                setFinance(null)
                setIsFinanceLoading(false)
            })
    }
}

const Container = styled.div`
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;

margin-top: 1rem;
position: relative;
 
& > div:first-child{

    width: 10rem;
    position: absolute;
    right: 0vw;
    top: -4.5rem;

    svg{
        font-size: 2.5rem;
        margin-left: 1rem;

        cursor: pointer;

        padding: .25rem;

        border-radius: 50%;

        border: 1px solid black;

        &:hover{
            background-color: rgba(200, 200, 200, .2);
        }
    }
}

& > div:last-child{

width: 10rem;
position: absolute;
left: 1vw;
top: -4.5rem;

svg{
    font-size: 2.5rem;
    margin-left: 1rem;

    cursor: pointer;

     

    border-radius: 50%;
 

    &:hover{
        background-color: rgba(200, 200, 200, .2);
    }
}
}

`

const LoaderContainer = styled.div`
 

width: 15rem;
height: 50vh;

display: flex;
justify-content: center;
align-items: center;


margin: auto;

`