import styled from "styled-components"
import { AiOutlineIdcard as CustomerIcon, AiOutlineLogout as LogoutIcon } from 'react-icons/ai';
import { BsCardChecklist as OrderIcon } from 'react-icons/bs';
import { TbToolsKitchen2 as KitchenIcon } from 'react-icons/tb';
import { FaBoxes as StockIcon } from 'react-icons/fa';
import { BsCashCoin as CashIcon } from 'react-icons/bs';
import { Link, useLocation } from "react-router-dom";

export default function SideMenu({name , logout, ip}) {
    const { pathname } = useLocation()

    return <Container className="sidebar">
        <Title>Chácara</Title>
        <hr />
        <NameHolder pWidth={name.length > 8 ? "9" : name.length}><p> {name} </p><LogoutIcon onClick={logout}/></NameHolder>
       

        <Menu>
            <MenuItem selected={pathname.includes("/clientes")} to="/clientes/criar">
                <li>

                    <CustomerIcon /> Clientes
                </li>
            </MenuItem>
            <MenuItem selected={pathname === "/"} to="/">
                <li >

                    <OrderIcon /> Pedidos
                </li>
            </MenuItem >  
            <MenuItem selected={pathname === "/cozinha"} to="/cozinha">
                <li>

                    <KitchenIcon /> Cozinha
                </li>
            </MenuItem>
            <MenuItem selected={pathname === "/estoque"} to="/estoque">
                <li>

                    <StockIcon /> Estoque
                </li>
            </MenuItem>
            <MenuItem selected={pathname.includes("/caixa")} to="/caixa/gerenciar">
                <li>

                    <CashIcon /> Caixa
                </li>
            </MenuItem>
        </Menu>
    </Container>
}


const Container = styled.div`
  min-width: 200px;
  height: 100vh;
 
  width: 12rem !important;
 

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  background-color: rgb(21,25,29);

  position: relative;

  hr{
    width: 85%;
    
    border: none;
    border-top: 1px solid grey;

    margin-bottom: 5rem;    
  }

  svg{
    margin-right: .5rem;
    cursor: pointer;
  }

`

const Title = styled.h1`
    font-size: 1.5rem;
    color: #f2f2f2;

    margin-top: 1rem;
`

const Menu = styled.ul`
width: 85%;

display: flex;
flex-direction: column;
justify-content: start;
align-items: flex-start;

list-style: none;
`

const MenuItem = styled(Link)`
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    height: 3rem;

    background-color: ${({ selected }) => selected ? "#0d6efd" : "rgb(21,25,29)"};

    font-size: 1.25rem;
    color: ${({ selected }) => selected ? "#ffffff" : "#f2f2f2"};
    
    margin-bottom: 1.5rem;
    padding-left: 2rem;
    border-radius: 9px;

    cursor: pointer;

    &:hover{
        filter: brightness(${({ selected }) => selected ? "120%" : "150%"});
    }

    li{
        color: ${({ selected }) => selected ? "#ffffff" : "#f2f2f2"}!important;
        background-color: inherit;

        &:hover{
        background-color: inherit;
        }
    }
    
`
const NameHolder = styled.div`
position: absolute;
width: 10.5rem;

color: red;

top: 4rem;
left: 1rem;

display: flex;
justify-content: center;

svg{
    margin-left: .5rem;
    font-size: 1.25rem;
}

p{
    width: ${({pWidth}) => pWidth}rem;
    text-align: right;
    font-size: 1.25rem;

    overflow: hidden;
    text-overflow: ellipsis;

}
`
const IpHolder = styled.div`
position: absolute;
width: 10.5rem;

color: red;

bottom: 1rem;
left: 1rem;

 

display: flex;
justify-content: center;

 

p{
    width: ${({pWidth}) => pWidth}rem;
    text-align: right;
    font-size: 1.25rem;

    overflow: hidden;
    text-overflow: ellipsis;

}
`