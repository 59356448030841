import React, { useContext, useEffect, useState } from 'react';
import {
  Br,
  Cut,
  Line,
  Printer,
  Row,
  Text,
  render,
  QRCode,
  Barcode
} from 'react-thermal-printer';
import formatMonetaryValue from '../helpers/formatMonetaryValue';
import dayjs from "dayjs"

import PrinterContext from '../context/printerContext';
import warningMessage from '../helpers/warnMessage';
import { v4 as uuid } from "uuid"



export default function usePrintOrder() {
  const { port, setPort } = useContext(PrinterContext);


  const printOrders = async (orders) => {
    const timestamp = dayjs().format("DD/MM/YY - HH:mm")

    const Order = <Printer  type="epson" width={48} characterSet="pc860_portuguese">

      {orders.map((p, i) => (
        <React.Fragment key={`product-${i}`}>
          <Row left={timestamp} right={<Text align='right'>identificador: {uuid().substring(0, 10)}</Text>} />
          
          <Line />
          <Text align="center" bold size={{ width: 2, height: 2 }}>Chácara do Wild</Text>
           
          <Line />
          <Row left="Produto" right="Quantidade" />
          <Row
            left={<Text align="left" bold
              size={{ width: 2, height: 1 }}
            > {p.name} </Text>}
            right={<Text align="left" bold
              size={{ width: 2, height: 1 }}
            > {p.selectedAmount}x </Text>} />
 
          <Line />

          <Text align='center'>Agradecemos a preferência!</Text>
          
 
          <Cut lineFeeds={4} />
        </React.Fragment>))}
    </Printer>

    print(Order)
  }
  //<Text align="right" bold size={{ width: 2, height: 2 }}>

  const print = async (Order) => {
    const data = await render(Order);

    let currentPort = port;

    if (!currentPort) {
      try {
        currentPort = await window.navigator.serial.requestPort();
        setPort(currentPort);
      } catch {
        warningMessage('Impressão não autorizada');


        return;
      }
    }

    try {
      await currentPort.open({ baudRate: 9600 });
    } catch {
      warningMessage('falha ao abrir porta');

    }

    try {
      const writer = currentPort.writable?.getWriter();

      await writer.ready;
      await writer.write(data);

      writer.releaseLock();

    } catch {
      warningMessage('falha ao imprimir pedido');
    } finally {
      currentPort.close();

    }
  };

  return { printOrders };
}
