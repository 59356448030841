import * as React from 'react';

import styled from 'styled-components';
import Fab from '@mui/material/Fab';
import axios from 'axios';
import ClientOrders from './ClientOrders';
import ClientInfo from './ClientInfo';
import {GoSearch as SearchIcon} from "react-icons/go"
import warningWaitingCard from '../../../helpers/warnMessage';
import Loader from '../../../Components/SideMenu/Loader';
import Swal from 'sweetalert2';
import { AuthContext } from '../../../context/AuthContext';


export default function DetailsPage() {
    const [client, setClient] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [headerInstructionsText, setHeaderInstructionsText] = React.useState('Selecione "buscar" e aproxime um cartão')

    const {user} = React.useContext(AuthContext)

    const getClient = () =>{
        const promisse = axios.get(`https://${user.ip}/api/client`)
        setIsLoading(true)
        
        warningWaitingCard()
        
        promisse.then(res=>{
            setClient(res.data)
            setIsLoading(false)
        })
        .catch(e => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `Erro: ${e.message}`,
              
              })
            setIsLoading(false)
            setHeaderInstructionsText('Selecione "buscar" e aproxime um cartão para tentar novamente')
        })
    }

    return (
        <Container isLoading={isLoading}>

            
            {client === null
            ?<>
            <HeaderInstructions>{isLoading? <Loader width="100" height="100"/> : headerInstructionsText}</HeaderInstructions>
            <Fab variant="extended" onClick={getClient} disabled={isLoading}>
            <SearchIcon/>
             Buscar
            </Fab>
            </>
            : <>
                <ClientInfo
                name={client.name}
                cpf={client.cpf}
                phoneNumber={client.phoneNumber}
                identifier={ client.identifier}
                />
                
                <ClientOrders orders={client.orders}/>
            </>}
        </Container>
    );
}


const Container = styled.div`
 display: flex;
 flex-direction: column;
 align-items: center;
 
 min-width: 550px;

svg{
    font-size: 2rem;
    margin-right: .5rem;
}


& > div:last-child{
    width: 70vw;

    margin-top: 1rem;
}

button{
margin-top: 2rem;
}

button > svg {
    font-size: 1.5rem;
}
`

const HeaderInstructions = styled.h1`
font-family: 'Roboto', sans-serif;
font-size: 1.5rem;

margin-top: 5rem;
`