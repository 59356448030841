import { IoMdCloseCircleOutline as CloseIcon } from 'react-icons/io'

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { FiSave as CreateIcon } from "react-icons/fi"

import { useState } from 'react';

import styled from "styled-components"
import * as React from 'react';

import axios from "axios";
import warningMessage from "../../../helpers/warnMessage";

import Fab from '@mui/material/Fab';
import Swal from "sweetalert2";
import formatMonetaryValue from '../../../helpers/formatMonetaryValue';

import { Checkbox, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { AuthContext } from '../../../context/AuthContext';
import Loader from '../../../Components/SideMenu/Loader';
import { useEffect } from 'react';


////////////////////////

export default function ReturnProductForm({ closeModal, updateStock}) {
 
    const [moneyValue, setMoneyValue] = useState(0);
    const [pixValue, setPixValue] = useState(0);
    const [cardValue, setCardValue] = useState(0);

    const [stock, setStock] = useState([])

    const [selectedProduct, setSelectedProduct] = useState('');
    const [productAmount, setProductAmount] = useState(0)

    const [isLoading, setIsloading] = useState(false)

    const handleChange = (event) => {
        setSelectedProduct(event.target.value);
    };

    const { user } = React.useContext(AuthContext)

    const refundValue = selectedProduct === "" ? 0 : ((stock[selectedProduct].price * productAmount*100) -moneyValue-pixValue-cardValue )
 
    useEffect(() => getCurrentStock(), [])

    return (
        <Container2 onClick={e => e.stopPropagation()} >
            <h1>Devolução de produto/serviço</h1>
 
            <Box sx={{ display: 'flex', justifyContent: "space-around", width: "100%", alignItems: 'center', marginRight: "2rem", marginBottom: "2rem" }}>
                <Box sx={{ display: 'flex', alignItems: 'flex-end', minWidth: '10rem' }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Produto</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedProduct}
                            label="Produto"
                            onChange={handleChange}
                        >
                            {stock ? stock.map((f, i) => (
                                <MenuItem value={i} key={`stock-item-${i}`}>{f.name}</MenuItem>
                            )) : "Carregando.."}



                        </Select>
                    </FormControl>

                </Box>

                <div style={{ width: "9rem", display: "flex", alignItems: "center", justifyContent: "space-between", fontSize: "1.5rem" }}>
                    <Fab variant="extended" sx={{ backgroundColor: "#f00000ab", fontSize: "1.5rem", height: "2rem" }} onClick={() => productAmount === 0 || setProductAmount(productAmount - 1)}  >
                        {<> - </>}
                    </Fab>
                    <p>{productAmount || 0}</p>
                    <Fab variant="extended" sx={{ backgroundColor: "#00f000ab", fontSize: "1.5rem", height: "2rem" }} onClick={() => { setProductAmount(productAmount + 1) }} >
                        {<> + </>}
                    </Fab>
                </div>

                <TextField
                    id="input-value"
                    label="A devolver"
                    required
                    variant="standard"
                    error={refundValue !== 0}

                    focused
                    value={`R$ ${refundValue < 0 ? "-":""} ${formatMonetaryValue((refundValue).toFixed(2))}`}

                    sx={{ width: "7rem" }}

                />



            </Box>

            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>



                <Box sx={{ display: 'flex', alignItems: 'flex-end', marginRight: "2rem" }}>

                    {  <TextField
                        id="input-value"
                        label="Devolvido dinheiro"
                        required
                        variant="standard"

                        focused
                        value={`R$ ${formatMonetaryValue(moneyValue)}`}
                        onChange={(e) => {
                            setMoneyValue(formatEventData(e));
                        }}

                        onClick={(e) => {
                            e.target.selectionStart = `R$ ${formatMonetaryValue(moneyValue)}`.length;
                            e.target.selectionEnd = `R$ ${formatMonetaryValue(moneyValue)}`.length;

                        }}


                        sx={{ width: "7rem" }}

                    />}
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'flex-end', marginRight: "2rem" }}>

                    {  <TextField
                        id="input-value"
                        label="Devolvido cartão"
                        required
                        variant="standard"

                        focused
                        value={`R$ ${formatMonetaryValue(cardValue)}`}
                        onChange={(e) => {
                            setCardValue(formatEventData(e));
                        }}

                        onClick={(e) => {
                            e.target.selectionStart = `R$ ${formatMonetaryValue(cardValue)}`.length;
                            e.target.selectionEnd = `R$ ${formatMonetaryValue(cardValue)}`.length;

                        }}


                        sx={{ width: "7rem" }}

                    />}
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'flex-end', marginRight: "2rem" }}>

                    {  <TextField
                        id="input-value"
                        label="Devolvido Pix"
                        required
                        variant="standard"

                        focused
                        value={`R$ ${formatMonetaryValue(pixValue)}`}
                        onChange={(e) => {
                            setPixValue(formatEventData(e));
                        }}

                        onClick={(e) => {
                            e.target.selectionStart = `R$ ${formatMonetaryValue(pixValue)}`.length;
                            e.target.selectionEnd = `R$ ${formatMonetaryValue(pixValue)}`.length;

                        }}


                        sx={{ width: "7rem" }}

                    />}
                </Box>


            </Box>



            <Box sx={{ display: 'flex', alignItems: 'flex-end', marginTop: "3rem" }}>
                <Fab disabled={isLoading} onClick={() => { closeModal(); setCardValue(0); setMoneyValue(0); setPixValue(0) }} variant="extended" sx={{ marginRight: "2rem" }}  >
                    {<><CloseIcon /> Cancelar</>}
                </Fab>
                <Fab disabled={isLoading} variant="extended" onClick={returnItem}  >
                    {<><CreateIcon />{isLoading? <Loader width={"2rem"} height={"2rem"}/> : "Registrar"}</>}
                </Fab>
            </Box>
        </Container2>
    )

    function formatEventData(e) {
        const lastAlphanumericKey = e?.nativeEvent?.data;

        return lastAlphanumericKey === null
            ? parseInt(e.target.value.replace(",", "").replace("R", "").replace("$", "").replace(" ", "").replace(" ", ""))
            : /^[0-9]$/.test(lastAlphanumericKey)
                ? parseInt(e.target.value.replace(",", "").replace("R", "").replace("$", "").replace(" ", ""))
                : parseInt(
                    e.target.value.replace(",", "").replace("R", "").replace("$", "").replace(" ", "").replace(lastAlphanumericKey, "")
                );
    }

    function returnItem() {

        if ((refundValue !== 0) )
            return warningMessage("Valor diferente do necessário")
            
        if (selectedProduct === "" )
        return warningMessage("Selecione um produto")
        

        Swal.fire({
            title: `Registrar devolução?`,
            text: `Você está prestes a registrar uma devolução de R$ ${formatMonetaryValue((stock[selectedProduct].price * productAmount*100).toFixed(2))} `,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: `Confirmar!`,
            cancelButtonText: "Cancelar"
        }).then((result) => {
            if (result.isConfirmed) {

                const orderTransaction = {
                    "orders": [ { product: { id: stock[selectedProduct].id }, amount:productAmount } ],
                    "moneyValue": moneyValue / 100,
                    "pixValue": pixValue / 100,
                    "cardValue": cardValue / 100
                }
                setIsloading(true)
                const promise = axios.post(`https://${user.ip}/api/orders/return`, orderTransaction, { headers: { Authorization: `Bearer ${user.token}` } })
                promise.then(res => {
                    
                    setMoneyValue(0)
                    setPixValue(0)
                    setCardValue(0)
                    setSelectedProduct("")
                    setProductAmount(0)

                    Swal.fire(
                        'Feito!',
                        `Devolução registrada!`,
                        'success'
                    )
                    setIsloading(false)

                    closeModal()
                })
                    .catch(err => {
                        setIsloading(false)
                        if (err?.response?.status === 401)
                            return warningMessage("Acesso não autorizado")
                        if (err?.response?.status === 409)
                            return warningMessage("Não foi possível devolver o produto. O caixa não está aberto")

                        warningMessage("Não foi possível devolver o produto. Erro inesperado")
                        
                    })

            }
        })


    }

    function getCurrentStock() {
        const promise = axios.get(`https://${user.ip}/api/products`, { headers: { Authorization: `Bearer ${user.token}` } })
        promise.then(res => {
            setStock(res.data);
        })
            .catch(err => {
                if (err?.response?.status === 401)
                      warningMessage("Acesso não autorizado")

                if (err?.response?.status !== 401)
                      warningMessage("Erro inesperado")

                
            })
    }

}

const Container2 = styled.div`
display:   flex ;

position: fixed;
z-index: 20;
background-color: white;

top: 20vh;
left: 10vw;

flex-direction: column;
justify-content: center;
align-items: center;

 width: 50vw;
margin-left: 20vw;


h1{
    font-size: 1.5rem;    

    margin-bottom: 2rem;
 
 
}

 

svg{
    font-size: 1.5rem
    ;
    margin-right: .5rem;
}

border: 1px dashed black;
border-radius: 9px;

padding-top: 2rem;
padding-bottom: 2rem;

`

