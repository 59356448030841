import { IoMdCloseCircleOutline as CloseIcon } from 'react-icons/io'

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { BiLockOpen as OpenIcon  } from "react-icons/bi"

import Fab from '@mui/material/Fab';
import formatMonetaryValue from '../../../helpers/formatMonetaryValue';
import { useContext, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import warningMessage from '../../../helpers/warnMessage';
import { AuthContext } from '../../../context/AuthContext';
import Loader from '../../../Components/SideMenu/Loader';
import Swal from 'sweetalert2';

export default function OpenFinanceForm({ isOpeningFinance, reloadFinance, cancel, setIsFinanceLoading }) {
   const [financeinitialMoney, setFinanceinitialMoney] = useState(0);
   const [annotations, setAnnotations] = useState("")
   const [isLoading, setIsloading] = useState(false)

   const { user } = useContext(AuthContext);
 
    return (
        <Container isChangingFinance={isOpeningFinance}>
            <h1> Abrir caixa</h1>

            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <Box sx={{ display: 'flex', alignItems: 'flex-end', }}>

                    <TextField
                        id="input-value"
                        label="Valor inicial"
                        required
                        variant="standard"
                        onChange={(e) => {
                            setFinanceinitialMoney(formatEventData(e));

                        }}
                        value={`R$ ${formatMonetaryValue(financeinitialMoney)}`}

                        onClick={(e) => {
                            e.target.selectionStart = `R$ ${formatMonetaryValue(financeinitialMoney)}`.length;
                            e.target.selectionEnd = `R$ ${formatMonetaryValue(financeinitialMoney)}`.length;
                        }}

                    />
                </Box>
             
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'flex-end', width: "30vw", margin: "2rem" }}>

                <TextField
                    id="input-description"
                    label="Descrição"
                    multiline
                    rows={4}
                    variant="filled"
                    sx={{ width: "30vw" }}
                    value={annotations}
                    onChange={e => setAnnotations(e.target.value)}
                />
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <Fab disabled={isLoading}  variant="extended" sx={{ marginRight: "2rem" }} onClick={cancel}>
                    {<><CloseIcon /> Cancelar</>}
                </Fab>
                <Fab disabled={isLoading} variant="extended"  onClick={openFinance} >
                    {<><OpenIcon /> {isLoading? <Loader width={"2rem"} height={"2rem"}/> : "Abrir"}</>}
                </Fab>
            </Box>
        </Container>
    )

    function openFinance(){

        Swal.fire({
            title: `abrir caixa com valor inicial de ${`R$ ${formatMonetaryValue((financeinitialMoney).toFixed(2))}`}?`,
            text: "Essa ação não pode ser desfeita!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: `Sim, abrir caixa!`,
            cancelButtonText: "Cancelar"

        }).then((result) => {
            if (result.isConfirmed) {
        const finance = { initialMoney: financeinitialMoney/100,  annotations: annotations}
        setIsloading(true)

        
        const promise = axios.post(`https://${user.ip}/api/finance`, finance, {headers:{Authorization: `Bearer ${user.token}`}})
        promise.then(res => {
            Swal.fire(
                'Sucesso!',
                `Caixa aberto!`,
                'success'
            )

            setIsFinanceLoading(true)
            reloadFinance()
            setIsloading(false)

            cancel()
        })
        .catch(err =>{
            const statusError = err?.response?.status
            if (statusError === 401)
                 warningMessage("Acesso não autorizado")

            if(statusError === 409)
                warningMessage("O caixa já está aberto")

            if(statusError !== 401 &&  statusError !== 409  )
             warningMessage("Erro inesperado")

            reloadFinance()
            setIsloading(false)
            setIsFinanceLoading(false)
            cancel()
        })
    }})
    }

    function formatEventData(e) {
        const lastAlphanumericKey = e?.nativeEvent?.data;

        return lastAlphanumericKey === null
            ? parseInt(e.target.value.replace(",", "").replace("R", "").replace("$", "").replace(" ", "").replace(" ", ""))
            : /^[0-9]$/.test(lastAlphanumericKey)
                ? parseInt(e.target.value.replace(",", "").replace("R", "").replace("$", "").replace(" ", ""))
                : parseInt(
                    e.target.value.replace(",", "").replace("R", "").replace("$", "").replace(" ", "").replace(lastAlphanumericKey, "")
                );
    }
}

const Container = styled.div`
display: ${({ isChangingFinance }) => isChangingFinance ? "flex" : "none"};

flex-direction: column;
justify-content: center;
align-items: center;

 width: 40vw;
margin-left: 1rem;


h1{
    font-size: 1.5rem;    

    margin-bottom: 2rem;
 
    span{
        color:"red";
    }
}


border: 1px dashed black;
border-radius: 9px;

padding-top: 2rem;
padding-bottom: 2rem;

`
