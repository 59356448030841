import { IoMdCloseCircleOutline as CloseIcon } from 'react-icons/io'

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { FiSave as CreateIcon } from "react-icons/fi"

import {  useState } from 'react';
 
import styled from "styled-components"
import * as React from 'react';
 
import axios from "axios";
import warningMessage from "../../helpers/warnMessage";
 
import Fab from '@mui/material/Fab';
import Swal from "sweetalert2";
import { AuthContext } from '../../context/AuthContext';
 
export default function ChangeStockForm({ item,closeModal,updateStock }) {
    const [addedAmount, setAddedAmount] = useState(0);
    const [finalAmount, setFinalAmount] = useState(item.stockAmount);

 const {user} = React.useContext(AuthContext)
 
    return (
        <Container2 onClick={e => e.stopPropagation()} >
            <h1>Adicionar estoque</h1>
            <h2>{item.name}</h2>

            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <Box sx={{ display: 'flex', alignItems: 'flex-end', marginRight: "3rem" }}>

                    <TextField
                        id="input-value"
                        label="Quantidade inicial"
                       
                        variant="standard"
                         
                        focused
                        value={Number(item.stockAmount)}


                        sx={{width:"7rem"}}

                    />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'flex-end', marginRight: "3rem" }}>

                    <TextField
                        id="input-value"
                        label="Quantidade adicionada"
                        required
                        variant="standard"
                        type="number"
                        focused
                        value={addedAmount}
                        onChange={(e)=>{
                            setAddedAmount(Number(e.target.value));
                            setFinalAmount(Number(item.stockAmount) + Number(e.target.value));
                        }}


                        sx={{width:"10rem"}}

                    />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'flex-end', marginRight: "3rem",}}>

                    <TextField
                        id="input-value"
                        label="Quantidade final"
                        required
                        variant="standard"
                        type="number"
                        focused

                        value={finalAmount }
                        onChange={e =>{
                            setFinalAmount(Number(e.target.value));
                            setAddedAmount(Number(e.target.value - item.stockAmount))
                        }}


                        sx={{width:"10rem"}}
                    />
                </Box>

            </Box>
            

            <Box sx={{ display: 'flex', alignItems: 'flex-end', marginTop:"3rem" }}>
                <Fab onClick={closeModal} variant="extended" sx={{ marginRight: "2rem" }}  >
                    {<><CloseIcon /> Cancelar</>}
                </Fab>
                <Fab variant="extended" onClick={()=>ChangeItemStock(item.id, item.name, addedAmount, finalAmount, updateStock)}  >
                    {<><CreateIcon /> Registrar</>}
                </Fab>
            </Box>
        </Container2>
    )

    
    function ChangeItemStock(productId, productName, amountToAdd,finalAmount, updateStock) {
        Swal.fire({
            title: `Adicionar estoque?`,
            text: `Você está prestes a adicionar ${amountToAdd} unidades ao estoque de ${productName}, totalizando um estoque de ${finalAmount}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: `Confirmar!`,
            cancelButtonText: "Cancelar"
        }).then((result) => {
            if (result.isConfirmed) {

                const promise = axios.post(`https://${user.ip}/api/products/${productId}?amountToAdd=${amountToAdd}`,{},{headers:{Authorization: `Bearer ${user.token}`}})
                promise.then(res => {
                    updateStock()
                    closeModal()

                    Swal.fire(
                        'Feito!',
                        `${amountToAdd} unidades de ${productName} adicionadas!`,
                        'success'
                    )
                })
                    .catch(err => {
                        if (err?.response?.status === 401)
                    return warningMessage("Acesso não autorizado")

                        if (err?.response?.status !== 404)
                            return warningMessage("Erro inesperado")

                        warningMessage("Esse produto não existe")
                        closeModal()
                    })

            }
        })


    }
     

     



     
}

const Container2 = styled.div`
display:   flex ;

position: fixed;
z-index: 20;
background-color: white;

top: 20vh;
left: 35vw;

flex-direction: column;
justify-content: center;
align-items: center;

 width: 40vw;
margin-left: 1rem;


h1{
    font-size: 1.5rem;    

    margin-bottom: .5rem;
 
 
}

h2{
    font-size: 1rem;    

    margin-bottom: 2rem;
 
}

svg{
    font-size: 1.5rem
    ;
    margin-right: .5rem;
}

border: 1px dashed black;
border-radius: 9px;

padding-top: 2rem;
padding-bottom: 2rem;

`

