import { IoMdCloseCircleOutline as CloseIcon } from 'react-icons/io'

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { FiSave as CreateIcon } from "react-icons/fi"

import Fab from '@mui/material/Fab';
import formatMonetaryValue from '../../../helpers/formatMonetaryValue';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';



import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';
import warningMessage from '../../../helpers/warnMessage';
import Swal from 'sweetalert2';
import { Checkbox } from '@mui/material';
import { AuthContext } from '../../../context/AuthContext';
import Loader from '../../../Components/SideMenu/Loader';

export default function FinanceChangeForm({ isChangingFinance, registerFinaceType, setRegisterFinanceType }) {
    const [financeChangeValue, setFinanceChangeValue] = useState(0);
    const [financeCategories, setFinanceCategories] = useState(null)
    const [selectedFinanceCategory, setSelectedFinanceCategory] = useState('');
    const [description, setDescription] = useState("");

    const [isLoading, setIsloading] = useState(false)
 

    const [moneyValue, setMoneyValue] = useState(0);
    const [pixValue, setPixValue] = useState(0);
    const [cardValue, setCardValue] = useState(0);

    const {user} = useContext(AuthContext);
 
    useEffect(() => getFinanceCategories(), [])
    
    const handleChange = (event) => {
        setSelectedFinanceCategory(event.target.value);
    };

    return (
        <Container isChangingFinance={isChangingFinance}>
            <h1>Registrar <span> {registerFinaceType === "income" ? "entrada" : "saída"} </span></h1>
            
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <Box sx={{ display: 'flex', alignItems: 'flex-end', marginRight: ".5rem", flexDirection: "row" }}>

                    { <TextField
                        id="input-value"
                        label="Pago dinheiro"
                        required
                        variant="standard"

                        focused
                        value={`R$ ${formatMonetaryValue(moneyValue)}`}
                        onChange={(e) => {
                            setMoneyValue(formatEventData(e));
                        }}

                        onClick={(e) => {
                            e.target.selectionStart = `R$ ${formatMonetaryValue(moneyValue)}`.length;
                            e.target.selectionEnd = `R$ ${formatMonetaryValue(moneyValue)}`.length;

                        }}


                        sx={{ width: "7rem" }}

                    />}
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'flex-end', marginRight: ".5rem" }}>

                    {  <TextField
                        id="input-value"
                        label="Pago cartão"
                        required
                        variant="standard"

                        focused
                        value={`R$ ${formatMonetaryValue(cardValue)}`}
                        onChange={(e) => {
                            setCardValue(formatEventData(e));
                        }}

                        onClick={(e) => {
                            e.target.selectionStart = `R$ ${formatMonetaryValue(cardValue)}`.length;
                            e.target.selectionEnd = `R$ ${formatMonetaryValue(cardValue)}`.length;

                        }}


                        sx={{ width: "7rem" }}

                    />}
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'flex-end', marginRight: ".5rem" }}>

                    {  <TextField
                        id="input-value"
                        label="Pago Pix"
                        required
                        variant="standard"

                        focused
                        value={`R$ ${formatMonetaryValue(pixValue)}`}
                        onChange={(e) => {
                            setPixValue(formatEventData(e));
                        }}

                        onClick={(e) => {
                            e.target.selectionStart = `R$ ${formatMonetaryValue(pixValue)}`.length;
                            e.target.selectionEnd = `R$ ${formatMonetaryValue(pixValue)}`.length;

                        }}


                        sx={{ width: "7rem" }}

                    />}
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>

                </Box>

                <Box sx={{ display: 'flex', alignItems: 'flex-end', minWidth: '10rem' }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Categoria</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedFinanceCategory}
                            label="Categoria"
                            onChange={handleChange}
                        >
                            {financeCategories ? financeCategories.map((f, i) => (
                                <MenuItem value={i} key={`finance-category-${i}`}>{f}</MenuItem>
                            )) : "Carregando.."}



                        </Select>
                    </FormControl>

                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'flex-end', width: "30vw", margin: "2rem" }}>

                <TextField
                    id="input-description"
                    label="Descrição"
                    multiline
                    rows={4}
                    variant="filled"
                    sx={{ width: "30vw" }}
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                />
            </Box>





            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <Fab disabled={isLoading} variant="extended" sx={{ marginRight: "2rem" }} onClick={() => { setRegisterFinanceType(""); setFinanceChangeValue(0) }}>
                    {<><CloseIcon /> Cancelar</>}
                </Fab>
                <Fab disabled={isLoading} variant="extended" onClick={() => SaveFinanceTransaction()}>
                    {<><CreateIcon /> {isLoading? <Loader width={"2rem"} height={"2rem"}/> : "Registrar"}</>}
                </Fab>
            </Box>
        </Container>
    )

    function formatEventData(e) {
        const lastAlphanumericKey = e?.nativeEvent?.data;

        return lastAlphanumericKey === null
            ? parseInt(e.target.value.replace(",", "").replace("R", "").replace("$", "").replace(" ", "").replace(" ", ""))
            : /^[0-9]$/.test(lastAlphanumericKey)
                ? parseInt(e.target.value.replace(",", "").replace("R", "").replace("$", "").replace(" ", ""))
                : parseInt(
                    e.target.value.replace(",", "").replace("R", "").replace("$", "").replace(" ", "").replace(lastAlphanumericKey, "")
                );
    }

    function getFinanceCategories() {
        const promise = axios.get(`https://${user.ip}/api/finance/transaction/categories`,{headers:{Authorization: `Bearer ${user.token}`}})
        promise.then(res => {
            setFinanceCategories(res.data);

        })
            .catch(err => {
                if (err?.response?.status === 401)
                    return warningMessage("Acesso não autorizado")

                
                     warningMessage("Erro inesperado")


            })
    }

    function SaveFinanceTransaction() {

        Swal.fire({
            title: `Registrar ${registerFinaceType === "income" ? "entrada" : "saída"} de ${`R$ ${formatMonetaryValue((moneyValue + pixValue+ cardValue).toFixed(2))}`}?`,
            text: "Essa ação não pode ser desfeita!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: `Sim, registrar ${registerFinaceType === "income" ? "entrada" : "saída"}!`,
            cancelButtonText: "Cancelar"
        }).then((result) => {
            if (result.isConfirmed) {
                const body = {

                    "type": registerFinaceType,
                    "category": selectedFinanceCategory,
                    "annotations": description,
                    "PixValue": pixValue / 100,
                    "MoneyValue": moneyValue / 100,
                    "CardValue": cardValue / 100,
                }

                setIsloading(true)
                const promise = axios.post(`https://${user.ip}/api/finance/transaction`, body, {headers:{Authorization: `Bearer ${user.token}`}})
                promise.then(() => {

                    setRegisterFinanceType("");
                    setFinanceChangeValue(0)
                    setIsloading(false)
                    Swal.fire(
                        'Feito!',
                        `${registerFinaceType === "income" ? "Entrada" : "Saída"} registrada!`,
                        'success'
                    )
                })
                    .catch(e => {
                        setIsloading(false)

                        if (e?.response?.status === 401)
                            return warningMessage("Acesso não autorizado")

                        if (e?.response?.status !== 400)
                            return warningMessage("Erro inesperado")

                        warningMessage("Categoria e/ou valor inválido")
                    })


            }
        })



    }
}

const Container = styled.div`
display: ${({ isChangingFinance }) => isChangingFinance ? "flex" : "none"};

flex-direction: column;
justify-content: center;
align-items: center;

 width: 40vw;
margin-left: 1rem;


h1{
    font-size: 1.5rem;    

    margin-bottom: 1rem;
 
    span{
        color:"red";
    }
}


border: 1px dashed black;
border-radius: 9px;

padding-top: 2rem;
padding-bottom: 2rem;

`
