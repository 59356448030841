import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Menu from './Menu';
import FinanceChangeForm from './FinanceChangeForm';
import FinanceInfo from './info';
import axios from 'axios';
import warningMessage from '../../../helpers/warnMessage';
import OpenFinanceForm from './OpenFinanceForm';
import { AuthContext } from '../../../context/AuthContext';
import ReturnProductForm from './ReturnProductForm';
import Loader from '../../../Components/SideMenu/Loader';

export default function Menagement() {
    const [finance, setFinance] = useState(null);
    const isFinanceOpen = (finance !== null)

    const [registerFinaceType, setRegisterFinanceType] = useState("");
    const isChangingFinance = (registerFinaceType !== "");

    const [isOpeningFinance, setIsOpeningFinance] = useState(false)

    const [isReturningProduct, setIsReturningProduct] = useState(false)
    const { user} =  useContext(AuthContext);

    const [isFinanceLoading, setIsFinanceLoading] = useState(true)


    useEffect(()=>{
        getCurrentOpenFinance()
    },[])



    return (
        <Container isChangingFinance={isChangingFinance}>
             <Cover onClick={() => { setIsReturningProduct(false) }} showModal={isReturningProduct}>
                <ReturnProductForm closeModal={()=> setIsReturningProduct(false)}/>
        </Cover>
            {isFinanceOpen && <FinanceInfo initialMoney={finance.initialMoney} startDate={finance.startDate} annotations={finance.annotations}/>}
            <div>
           {isFinanceLoading? <LoaderContainer> <Loader width={"8rem"} height={"8rem"}/></LoaderContainer> : <Menu setIsFinanceLoading={setIsFinanceLoading} openFinance={() => setIsOpeningFinance(true)} isOpeningFinance={isOpeningFinance} isFinanceOpen={isFinanceOpen} reloadFinance={() => getCurrentOpenFinance()} isChangingFinance={isChangingFinance} setRegisterFinanceType={setRegisterFinanceType} OpenReturnItemForm={() => setIsReturningProduct(true)}/>
           } <FinanceChangeForm isChangingFinance={isChangingFinance}  registerFinaceType={registerFinaceType} setRegisterFinanceType={setRegisterFinanceType}/>
            <OpenFinanceForm setIsFinanceLoading={setIsFinanceLoading} isOpeningFinance={isOpeningFinance}  reloadFinance={() => getCurrentOpenFinance()} cancel={()=> setIsOpeningFinance(false)}/>
            </div>
        </Container>
    )

    function getCurrentOpenFinance(){
        const promise = axios.get(`https://${user.ip}/api/finance/open`, {headers:{Authorization: `Bearer ${user.token}`}})
        promise.then(res => {
            setFinance(res.data);
            setIsFinanceLoading(false)
        })
        .catch(err =>{
            const errStatus = err?.response?.status
            if (errStatus === 401)
                      warningMessage("Acesso não autorizado")

            if(errStatus !== 404 && errStatus !== 401)
                  warningMessage("Erro inesperado")

            setFinance(null)
            setIsFinanceLoading(false)
        })
    }




}

const Container = styled.div`
height: calc(100vh - 20rem);

display: flex;
flex-direction: column;
align-items: center;



& > div:last-child {
    display: flex;
align-items: center;
justify-content: space-evenly;

margin: auto;
}


svg{
    margin-right: .5rem;
    font-size  : 1.75rem;
}
 
`

const Cover = styled.div`
display:  ${({ showModal }) => showModal ? "block" : "none"} ;
width: 100vw;
height: 100vh;

position: fixed;
left: 0;
top: 0;

z-index: 10;
background-color: rgba(0,0,0,.4);
`

const LoaderContainer = styled.div`
 

width: 15rem;
height: 50vh;

display: flex;
justify-content: center;
align-items: center;


margin: auto;

`