import { IoMdCloseCircleOutline as CloseIcon } from 'react-icons/io'

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { FiSave as CreateIcon } from "react-icons/fi"

import { useState } from 'react';

import styled from "styled-components"
import * as React from 'react';

import axios from "axios";
import warningMessage from "../../helpers/warnMessage";

import Fab from '@mui/material/Fab';
import Swal from "sweetalert2";
import formatMonetaryValue from '../../helpers/formatMonetaryValue';
 
import { Checkbox } from '@mui/material';
import { AuthContext } from '../../context/AuthContext';
import Loader from '../../Components/SideMenu/Loader';
 
 

////////////////////////

export default function FinishOrderForm({ closeModal, updateStock, totalValue, selectedItems, resetSelectedItems, IsSavingOrderLoading, setIsSavingOrderLoading }) {
 
    const [moneyValue, setMoneyValue] = useState(0);
    const [pixValue, setPixValue] = useState(0);
    const [cardValue, setCardValue] = useState(0);

    const {user} = React.useContext(AuthContext)

    const [isLoading, setIsloading] = useState(false)


    const changeMoney = moneyValue > 0 ? moneyValue - (totalValue - pixValue - cardValue) : 0

    return (
        <Container2 onClick={e => e.stopPropagation()} >
            <h1>Regitrar produto/serviço</h1>

 

            <Box sx={{ display: 'flex', justifyContent:"space-around", width: "100%", alignItems: 'flex-end', marginRight: "2rem", marginBottom: "2rem" }}>

                <TextField
                    id="input-value"
                    label="Total"
                    required
                    variant="standard"

                    focused
                    value={`R$ ${formatMonetaryValue(totalValue.toFixed(2))}`}
 
                    sx={{ width: "7rem" }}

                />

                <TextField
                    id="input-value"
                    label="Troco"
                    required
                    error
                    variant="standard"

                    focused
                    value={`R$ ${(moneyValue > 0 && changeMoney < 0) ? "-" : ""} ${formatMonetaryValue((moneyValue > 0 ? changeMoney : 0).toFixed(2))}`}
                     
                    sx={{ width: "7rem" }}

                />


            </Box>

            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>

        

                <Box sx={{ display: 'flex', alignItems: 'flex-end', marginRight: "2rem" }}>

                    {   <TextField
                        id="input-value"
                        label="Pago dinheiro"
                        required
                        variant="standard"

                        focused
                        value={`R$ ${formatMonetaryValue(moneyValue)}`}
                        onChange={(e) => {
                            setMoneyValue(formatEventData(e));
                        }}

                        onClick={(e) => {
                            e.target.selectionStart = `R$ ${formatMonetaryValue(moneyValue)}`.length;
                            e.target.selectionEnd = `R$ ${formatMonetaryValue(moneyValue)}`.length;

                        }}


                        sx={{ width: "7rem" }}

                    />}
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'flex-end', marginRight: "2rem" }}>

                    {  <TextField
                        id="input-value"
                        label="Pago cartão"
                        required
                        variant="standard"

                        focused
                        value={`R$ ${formatMonetaryValue(cardValue)}`}
                        onChange={(e) => {
                            setCardValue(formatEventData(e));
                        }}

                        onClick={(e) => {
                            e.target.selectionStart = `R$ ${formatMonetaryValue(cardValue)}`.length;
                            e.target.selectionEnd = `R$ ${formatMonetaryValue(cardValue)}`.length;

                        }}


                        sx={{ width: "7rem" }}

                    />}
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'flex-end', marginRight: "2rem" }}>

                   {  <TextField
                        id="input-value"
                        label="Pago Pix"
                        required
                        variant="standard"

                        focused
                        value={`R$ ${formatMonetaryValue(pixValue)}`}
                        onChange={(e) => {
                            setPixValue(formatEventData(e));
                        }}

                        onClick={(e) => {
                            e.target.selectionStart = `R$ ${formatMonetaryValue(pixValue)}`.length;
                            e.target.selectionEnd = `R$ ${formatMonetaryValue(pixValue)}`.length;

                        }}


                        sx={{ width: "7rem" }}

                    />}
                </Box>


            </Box>



            <Box sx={{ display: 'flex', alignItems: 'flex-end', marginTop: "3rem" }}>
                <Fab disabled={IsSavingOrderLoading} onClick={() => {closeModal(); setCardValue(0); setMoneyValue(0); setPixValue(0)}} variant="extended" sx={{ marginRight: "2rem" }}  >
                    {<><CloseIcon /> Cancelar</>}
                </Fab>
                <Fab disabled={IsSavingOrderLoading} variant="extended" onClick={createItem}  >
                    {<><CreateIcon />{IsSavingOrderLoading ? <Loader width={"2rem"} height={"2rem"}/>  : 'Registrar'}</>}
                </Fab>
            </Box>
        </Container2>
    )

    function formatEventData(e) {
        const lastAlphanumericKey = e?.nativeEvent?.data;

        return lastAlphanumericKey === null
            ? parseInt(e.target.value.replace(",", "").replace("R", "").replace("$", "").replace(" ", "").replace(" ", ""))
            : /^[0-9]$/.test(lastAlphanumericKey)
                ? parseInt(e.target.value.replace(",", "").replace("R", "").replace("$", "").replace(" ", ""))
                : parseInt(
                    e.target.value.replace(",", "").replace("R", "").replace("$", "").replace(" ", "").replace(lastAlphanumericKey, "")
                );
    }

    function createItem() {

        if((pixValue + moneyValue + cardValue) < totalValue)
            return warningMessage("Pagamento menor que o valor total")

       

        Swal.fire({
            title: `Registrar compra?`,
            text: `Você está prestes a registrar uma compra de R$ ${formatMonetaryValue(totalValue.toFixed(2))} `,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: `Confirmar!`,
            cancelButtonText: "Cancelar"
        }).then((result) => {
            if (result.isConfirmed) {
                setIsSavingOrderLoading(true)

                const orderTransaction = {
                    "orders": selectedItems.map(s => {return {product:{id: s.id}, amount: s.selectedAmount}}),
                    "moneyValue": moneyValue/100,
                    "pixValue": pixValue/100,
                    "cardValue": cardValue/100
                }

                const promise = axios.post(`https://${user.ip}/api/orders`, orderTransaction, {headers:{Authorization: `Bearer ${user.token}`}})
                promise.then(res => {
                    setIsSavingOrderLoading(false)
                    updateStock()
                    setMoneyValue(0)
                    setPixValue(0)
                    setCardValue(0)
                    resetSelectedItems()
                    closeModal()

                    Swal.fire(
                        'Feito!',
                        `Compra registrada!`,
                        'success'
                    ) 


                })
                    .catch(err => {
                        setIsSavingOrderLoading(false)
                        if (err?.response?.status === 401)
                    return warningMessage("Acesso não autorizado")
                    if (err?.response?.status === 409)
                    return warningMessage("O caixa não está aberto")

                        warningMessage("Não foi possível registrar a copmpra. Erro inesperado")
                        
                    })

            }
        })


    }

}

const Container2 = styled.div`
display:   flex ;

position: fixed;
z-index: 20;
background-color: white;

top: 20vh;
left: 10vw;

flex-direction: column;
justify-content: center;
align-items: center;

 width: 50vw;
margin-left: 20vw;


h1{
    font-size: 1.5rem;    

    margin-bottom: 2rem;
 
 
}

 

svg{
    font-size: 1.5rem
    ;
    margin-right: .5rem;
}

border: 1px dashed black;
border-radius: 9px;

padding-top: 2rem;
padding-bottom: 2rem;

`

