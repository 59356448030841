 
import styled from "styled-components";
import {GrHostMaintenance} from "react-icons/gr"
import { useState } from "react";
import { useEffect } from "react";

export default function InProgressPage(){
const [dots, setDots] = useState([])


useEffect(()=>{
  setTimeout(()=>{
 
    if(dots.length < 3)
        setDots([...dots,"."])
    else    
        setDots([])
}, 500) 

}, [dots])

return <Container>

<GrHostMaintenance/> <p>Em progresso{dots.join("")}</p>
</Container>
}

const Container = styled.div`
width: 50vw;
height: 100vh;

margin: auto;

display: flex;
justify-content: center;
align-items: center;
 
 font-size: 2rem;

 svg{
    margin-right: 2rem;
 }

 p{
    width: 20rem;
 }
 `