import * as React from 'react';
import Box from '@mui/material/Box';

import TextField from '@mui/material/TextField';
import { CgProfile as AccountCircle } from "react-icons/cg"
import { FiSave as CreateIcon } from "react-icons/fi"
import styled from 'styled-components';
import Fab from '@mui/material/Fab';
import Loader from '../../Components/SideMenu/Loader';
import Swal from 'sweetalert2'

import { HiOutlineIdentification as IdentifierIcon } from "react-icons/hi"
import { RiLockPasswordLine as PassIcon } from "react-icons/ri"

import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import warningMessage from '../../helpers/warnMessage';
import useLocalStorage from 'use-local-storage';

export default function LoginPage() {
    const [name, setName] = React.useState("");
    const [isNameEmpty, setIsNameEmpty] = React.useState(false);
    const [password, setPassword] = React.useState("");
    const [isPasswordEmpty, setIsPasswordEmpty] = React.useState(false);
    const [loginTimeout, setLoginTimeout] = React.useState(0)

    const [isLoading, setIsLoading] = React.useState(false);
    const { login } = React.useContext(AuthContext);

    const [user, setUser] = useLocalStorage('user', { });

    const serverIp = "admin.chacara-do-wild.com"
 

    const nav = useNavigate();
    
    React.useEffect(() => {
        
        if (!!user.token ) {
            nav("/")
        }

    },[])

    React.useEffect(() => {
        
        if (!!loginTimeout) {
          setTimeout(() => setLoginTimeout(loginTimeout - 1), 1000)    
        }

    },[loginTimeout])

   
    const handleSubmit = () => {

        if (name.trim().length === 0) {
            setIsNameEmpty(true);
            return
        }

        if (password.trim().length === 0) {
            setIsPasswordEmpty(true);
            return
        }

        setIsNameEmpty(false);
        setIsLoading(true);

        const clientData = { name, password };


        const promise = axios.post(`https://${serverIp}/api/staff`, clientData)
        promise.then((res) => {
            login(res.data.token, name, user.ip)
            setIsLoading(false);
            nav("/")
        }).catch(e => {

            if (e?.response?.status === 401) {
                setIsLoading(false);
                const secondsSinceLastValidTry = e?.response?.data?.timeout
                if(!!secondsSinceLastValidTry)
                    setLoginTimeout(Math.ceil( 300 - secondsSinceLastValidTry))

               return warningMessage("Código de acesso inválido")
            }

            warningMessage("Erro inesperado")
            setIsLoading(false);
        })
    }

    return (
        <Container isLoading={isLoading}>
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <AccountCircle />
                <TextField
                    disabled={isLoading || !!loginTimeout}
                    id="input-name"
                    label="Nome"
                    error={isNameEmpty}
                    required
                    variant="standard"

                    onChange={e => setName(e.target.value)}
                    value={name}
                />
            </Box>
            
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <PassIcon />
                <TextField
                    disabled={isLoading || !!loginTimeout}
                    error={isPasswordEmpty}
                    id="input-senha"
                    label="Código de acesso"
                    variant="standard"
                    type="password"
                    onChange={e => setPassword(e.target.value)}
                    value={password}
                />
            </Box>
            {!!loginTimeout && <p style={{color:"red",marginBottom:"1rem" }}>Limite de tentativas atingido. Tente novamente em 0{Math.trunc(loginTimeout/60)}:{loginTimeout%60 < 10 ? `0${loginTimeout%60}`:loginTimeout%60}</p>}
            <Fab variant="extended" disabled={isLoading || !!loginTimeout} onClick={handleSubmit}>

                {isLoading ? <Loader height="50" width="58" /> : <> Entrar</>}
            </Fab>

        </Container>
    );
}


const Container = styled.div`
 height: 100vh;
 width: 100vw;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
 

svg{
    font-size: 2rem;
    margin-right: .5rem;
}

 div{
    width: 30vw;
}

& > div {
    margin-bottom: 2rem;
}

& > button{
 
    div{
        width: auto;
    }
    
    svg{
       font-size:1.5rem;
        ${({ isLoading }) => isLoading && "margin-right: 0;"}
    }
 
}

`