import React, { createContext, useState } from 'react';
import useLocalStorage from 'use-local-storage';

export const AuthContext = createContext();

export default function AuthProvider ({ children }) {
    const [user, setUser] = useLocalStorage('user', {ip:"admin.chacara-do-wild.com"}); 

  const login = (token, name) => {
    setUser({ ...user, token, name });
  };

  const logout = () => {
    setUser({ip:user.ip});
  };

  const saveIp = (ip) => {
   
  }

  return (
    <AuthContext.Provider value={{ user, login, logout, saveIp }}>
      {children}
    </AuthContext.Provider>
  );
};

 