import styled from "styled-components"
import Box from '@mui/material/Box';

import TextField from '@mui/material/TextField';
import { BsCashCoin as MoneyIcon } from "react-icons/bs"
import { MdOutlineDateRange as CalendarIcon, MdOutlineDescription as DescriptionIcon, MdExpandMore as ExpandMoreIcon, MdExpandLess as ExpandLessIcon } from "react-icons/md"

import formatMonetaryValue from "../../../../helpers/formatMonetaryValue";
import dayjs from "dayjs";
import { useState } from "react";
import { Checkbox } from "@mui/material";


export default function FinanceInfo({ startDate, initialMoney, annotations }) {
    const [isShowingDescription, setIsShowingDescription] = useState(false);
    const formatedDate = dayjs(startDate).format("DD/MM/YY - HH:mm")



    return (<Container  >
        <div>
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <CalendarIcon />
                <TextField
                    id="input-name"
                    variant="standard"
                    label="Abertura"
                    value={formatedDate}
                    focused

                />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <MoneyIcon />
                <TextField

                    id="input-cpf"
                    variant="standard"
                    label="Valor inicial"
                    value={`R$ ${formatMonetaryValue(initialMoney * 100)}`}
                    focused
                />
            </Box>
        </div>
        <Box sx={{ display: 'flex', alignItems: 'flex-end', width: "30vw", margin: "2rem", flexDirection: "column" }}>
           {isShowingDescription ? <ExpandLessIcon className="clickable" onClick={() => setIsShowingDescription(false)} /> : <ExpandMoreIcon className="clickable" onClick={() => setIsShowingDescription(true)} />}
            <TextField
                id="input-description"
                label="Descrição"
                multiline
                rows={isShowingDescription ? 4 : 0}
                variant="filled"
                sx={{ width: "30vw" }}
                value={annotations}


                focused
            />
        </Box>


    </Container>
    )
}

const Container = styled.div`
    width: 80vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
     

    div, input {
    min-width: 115px;
     
    overflow: hidden;
   
 }

&>div:first-child{
    width: 40vw;
    display: flex;
   
    justify-content: space-around;
    flex-wrap: wrap;

    margin-bottom: 1rem;
}

.clickable{
    cursor: pointer;
}

`