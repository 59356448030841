import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom"
import styled from "styled-components";
import SideMenu from "./Components/SideMenu/SideMenu";
import ClientPage from "./Pages/Clients";
import GlobalStyle from "./styles/GlobalStyles";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FinancePage from "./Pages/finance";
import StockPage from "./Pages/stock";
import InProgressPage from "./Pages/InProgressPage";
import OrderPage from "./Pages/order";
import AuthProvider, { AuthContext } from "./context/AuthContext";
import { useContext, useEffect } from "react";
import LoginPage from "./Pages/login";
import { PrinterProvider } from "./context/printerContext";
 
function App() { 
  return (
    <div className="App">
      <GlobalStyle />
      
      <Container>
        <ToastContainer />
        <BrowserRouter>
          <Routes>

            <Route path="/" element={<ContainerWrapper><OrderPage /></ContainerWrapper>} />
            <Route path="/clientes/*" element={<ContainerWrapper> <InProgressPage /> </ContainerWrapper>} />
            <Route path="/cozinha" element={<ContainerWrapper><InProgressPage /></ContainerWrapper>} />
            <Route path="/estoque" element={<ContainerWrapper><StockPage /></ContainerWrapper>} />
            <Route path="/caixa/*" element={<ContainerWrapper><FinancePage /></ContainerWrapper>} />
            <Route path="/login" element={<ContainerWrapper><LoginPage /></ContainerWrapper>} />
          </Routes>
        </BrowserRouter>

      </Container>
    </div>
  );
}

const Container = styled.div`
display: flex;
height: 100vh;
width: 100vw;




& > main {
  width: 88vw !important;
}
`

function ContainerWrapper({ children }) {
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
 
    if (!user.token ) {
      navigate("/login")
    }
  }, [user])

 
  return (
    <PrinterProvider>
    <Container>
      {user.token && <SideMenu name={user.name} logout={logout} ip={user.ip} />}
      <main>{children}</main>
    </Container>
    </PrinterProvider>
  )
}

export default App;
