import * as React from 'react';
import Box from '@mui/material/Box';
 
import TextField from '@mui/material/TextField';
 import {CgProfile as AccountCircle} from "react-icons/cg"
 import {FiSave as CreateIcon} from "react-icons/fi"
import styled from 'styled-components';
import Fab from '@mui/material/Fab';
import Loader from '../../Components/SideMenu/Loader';
import Swal from 'sweetalert2' 
import warningWaitingCard from '../../helpers/warnMessage';
import {HiOutlineIdentification as IdentifierIcon} from "react-icons/hi"
import {MdPhoneInTalk as PhoneIcon} from "react-icons/md"

import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';

export default function RegisterClient(){
    const [name, setName] = React.useState("");
    const [isNameEmpty, setIsNameEmpty] = React.useState(false);
    const [cpf, setCpf] = React.useState("");
    const [phoneNumber, setPhoneNumber] = React.useState("");

    const [isLoading, setIsLoading] = React.useState(false);

    const {user} = React.useContext(AuthContext);

    const handleSubmit = ()=>{
        if(name.trim().length === 0){
            setIsNameEmpty(true);
            return
        }
        setIsNameEmpty(false);
        setIsLoading(true);

        warningWaitingCard()

        const clientData = {name};

        if(phoneNumber.trim().length > 0)
            clientData.phoneNumber = phoneNumber

        if(cpf.trim().length > 0)
            clientData.cpf = cpf


       const promise = axios.post(`https://${user.ip}/api/client`, clientData)
        promise.then(()=>{
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Salvo!',
                showConfirmButton: false,
                timer: 1500
              })
            
              setIsLoading(false);
        }).catch(e=>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `Erro: ${e.message}`,
              })
              setIsLoading(false);
        })
    }

    return  (
        <Container isLoading={isLoading}>
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <AccountCircle />
                <TextField
                disabled={isLoading} 
                id="input-name" 
                label="Nome" 
                error={isNameEmpty} 
                required 
                variant="standard" 

                onChange={e=>setName(e.target.value)}
                value={name}
                />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <IdentifierIcon />
                <TextField
                disabled={isLoading} 
                id="input-cpf" 
                label="Cpf" 
                variant="standard" 
                onChange={e=>setCpf(e.target.value)}
                value={cpf}
                />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <PhoneIcon />
                <TextField
                disabled={isLoading} 
                id="input-contato" 
                label="Contato" 
                variant="standard" 
                onChange={e=>setPhoneNumber(e.target.value)}
                value={phoneNumber}
                />
            </Box>
            <Fab variant="extended" onClick={handleSubmit}>
                 
                   {isLoading ? <Loader height="50" width="58"/> : <><CreateIcon/> Salvar</> }
            </Fab>
      
        </Container>        
      );
}


const Container = styled.div`
 height: 60vh;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
 

svg{
    font-size: 2rem;
    margin-right: .5rem;
}

 div{
    width: 30vw;
}

& > div {
    margin-bottom: 2rem;
}

& > button{
 
    div{
        width: auto;
    }
    
    svg{
       font-size:1.5rem;
        ${({isLoading})=> isLoading && "margin-right: 0;"}
    }
 
}

`