import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import getFinancePdf from '../../../helpers/getFinancePdf';



export default function Financestable({finances}){
  const nav = useNavigate();  
    finances = finances ? finances : [];

    //finances.length > 0 && getFinancePdf(finances, finances[0].startDate, finances[finances.length -1].endDate, false)

    const rows =  finances.map(f => {return {id: f.id, startDate: dayjs(f.startDate).format("DD/MM/YY - HH:mm"), endDate: f.endDate ? dayjs(f.endDate).format("DD/MM/YY - HH:mm"):"-"}})
 
    return (
        <Container>
            
        <TableContainer component={Paper} sx={{width:"70vw", marginTop:"3rem"}}>
          <Table sx={{ minWidth: 500 }} aria-label="simple table" stickyHeader>
            <TableHead fixed="true">
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell align="right">Data de abertura</TableCell>
                <TableCell align="right">Data de fechamento</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className='tableBody'>
              {rows.map((row) => (
                <TableRow
                className='clickable'
                onClick={() => nav(`${row.id}`)}
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
           
                  <TableCell align="right">{row.startDate}</TableCell>
                  <TableCell align="right">{row.endDate}</TableCell> 
                </TableRow>
              ))}
               
            </TableBody>
          </Table>
        </TableContainer>
        </Container>
      );
}

const Container = styled.div`
height: 80vh;
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;

 
.clickable{
    cursor: pointer;
    
    &:hover{
        background-color: rgba(0,0,0,.1);
    }
}
 
`

const HeaderInstructions = styled.h1`
font-family: 'Roboto', sans-serif;
font-size: 1.5rem;

margin-top: 3rem;
`