
import dayjs from "dayjs";

import jsPDF from "jspdf";

import formatMonetaryValue from "./formatMonetaryValue";



export default function getFinancePdf(finances, startDate, endDate, shouldSave, shouldDetailOrders = true) {
    const doc = new jsPDF();
    console.log(finances);
    // Set font and font size
    doc.setFont("helvetica", "bold");
    doc.setFontSize(22);

    // Add heading to PDF
    doc.text("Relatório de Caixa", 45, 15);

    // Set font and font size
    doc.setFont("helvetica", "normal");
    doc.setFontSize(12);

    doc.text(` ${dayjs(startDate).format("DD/MM/YYYY")} - ${endDate ? dayjs(endDate).format("DD/MM/YYYY") : dayjs().format("DD/MM/YYYY")}`, 155, 15);


    doc.setFont("helvetica", "normal");

    doc.setFontSize(16);

    /*

    doc.text(`Entradas no período:`, 2, 35);
    doc.setTextColor("#00ab00")
    doc.text(`${income}`, 57, 35);

    doc.setTextColor("#000000")
    doc.text(`Saídas no período:`, 2, 43);
    doc.setTextColor("#d00000")
    doc.text(`${outcome}`, 53, 43);

    */

    doc.line(0, 25, 210, 25)
    //doc.line(0, 50, 210, 50)

    doc.line(150, 25, 150, 0)

    // Set font and font size
    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);
    doc.setTextColor("#000000")
    // Add heading to PDF

    let startingFinanceTransactionsLine = 40
    doc.text("Entradas e saídas", 80, startingFinanceTransactionsLine);

    if (finances?.length > 1) {
        startingFinanceTransactionsLine += 43
    }

    let totalMoneyIncomeBalance = 0
    let totalMoneyOutcomeBalance = 0
    let totalPixIncomeBalance = 0
    let totalPixOutcomeBalance = 0
    let totalCardIncomeBalance = 0
    let totalCardOutcomeBalance = 0

    const totalSalesByProductId = {}
    const totalReturnsByproductId = {}


    /// generate finance total sales and returns

    finances?.forEach(finance => {
        finance?.transactions?.forEach((currTransaction) => {

            if (currTransaction.category === "Venda") {
                const orders = currTransaction.orders

                orders.forEach(o => {
                    const productId = o.product.id

                    if (totalSalesByProductId[productId] === undefined) {
                        totalSalesByProductId[productId] = { ...o }
                    } else {
                        totalSalesByProductId[productId].amount = totalSalesByProductId[productId].amount + o.amount
                    }
                })
            }


            if (currTransaction.category === "Devolucao") {
                const orders = currTransaction.orders

                orders.forEach(o => {
                    const productId = o.product.id

                    if (totalSalesByProductId[productId] === undefined) {
                        totalSalesByProductId[productId] = { ...o }
                        totalSalesByProductId[productId].amount = -1 * totalSalesByProductId[productId].amount
                    } else {
                        totalSalesByProductId[productId].amount = totalSalesByProductId[productId].amount - o.amount
                    }

                    if (totalReturnsByproductId[productId] === undefined) {
                        totalReturnsByproductId[productId] = { ...o }
                    } else {
                        totalReturnsByproductId[productId].amount = totalReturnsByproductId[productId].amount + o.amount
                    }

                })
            }

            //////////////////////////////////  criação dinamica pedidos///////////////////////////////////////////////////////////           ////////////////////////////////////

        })
    })


    if (finances?.length > 1) {
        let currentLine = startingFinanceTransactionsLine

        //////////////// items vendidos
        if ((currentLine + 50) / 280 > 1) {
            doc.addPage()

            currentLine = 10
        }

        currentLine += 15
        doc.setFont("helvetica", "bold");
        doc.setFontSize(12)



        doc.text(`Vendas consolidadas de Produtos/serviços do período de ${dayjs(startDate).format("DD/MM/YYYY")} a ${endDate ? dayjs(endDate).format("DD/MM/YYYY") : dayjs().format("DD/MM/YYYY")}`, 23, currentLine);

        doc.setFontSize(10)
        currentLine += 10
        let startProductsTableX = 10
        let startProductsTableY = currentLine - 5;
        let endTableX = 200

        const productNameLabelPosition = startProductsTableX + 2
        const buyPriceLabelPosition = startProductsTableX + 55
        const costsLabelPosition = startProductsTableX + 85
        const salePriceLabelPosition = startProductsTableX + 110
        const amountlabelPosition = startProductsTableX + 140

        const profitLabelPosition = startProductsTableX + 165

        doc.line(startProductsTableX, startProductsTableY, endTableX, startProductsTableY)
        doc.text("Produto/serviço", productNameLabelPosition, currentLine)

        doc.text("Valor compra", buyPriceLabelPosition, currentLine)
        doc.text("Custos", costsLabelPosition, currentLine)
        doc.text("Valor Venda", salePriceLabelPosition, currentLine)
        doc.text("Quantidade", amountlabelPosition, currentLine)
        doc.text("Lucro", profitLabelPosition, currentLine)


        currentLine += 2
        doc.line(startProductsTableX, currentLine, endTableX, currentLine)
        doc.setFont("helvetica", "normal");
        currentLine += 1.5
        let totalProfit = 0
        for (const productId in totalSalesByProductId) {

            if ((currentLine + 20) / 280 > 1) {

                doc.line(startProductsTableX, startProductsTableY, startProductsTableX, currentLine - 1.5)
                 
                doc.line(endTableX, startProductsTableY, endTableX, currentLine - 1.5)
                doc.line(buyPriceLabelPosition - 3, startProductsTableY, buyPriceLabelPosition - 3, currentLine - 1.5)
                doc.line(salePriceLabelPosition - 3, startProductsTableY, salePriceLabelPosition - 3, currentLine - 1.5)
                doc.line(costsLabelPosition - 3, startProductsTableY, costsLabelPosition - 3, currentLine - 1.5)
                doc.line(amountlabelPosition - 3, startProductsTableY, amountlabelPosition - 3, currentLine - 1.5)
                doc.line(profitLabelPosition - 3, startProductsTableY, profitLabelPosition - 3, currentLine - 1.5)
                doc.line(endTableX, startProductsTableY, endTableX, currentLine - 1.5)
                doc.setFont("helvetica", "normal");
               
                doc.addPage()

                currentLine = 10
                startProductsTableY = currentLine - 5;

                doc.line(startProductsTableX, startProductsTableY, endTableX, startProductsTableY)
                doc.setFont("helvetica", "bold");
                
                doc.text("Produto/serviço", startProductsTableX + 2, currentLine)
                doc.text("Valor compra", buyPriceLabelPosition, currentLine)
                doc.text("Custos", costsLabelPosition, currentLine)
                doc.text("Valor Venda", salePriceLabelPosition, currentLine)
                doc.text("Quantidade", amountlabelPosition, currentLine)
                doc.text("Lucro", profitLabelPosition, currentLine)
                doc.setFont("helvetica", "normal");


                currentLine += 2
                doc.line(startProductsTableX, currentLine, endTableX, currentLine)

                doc.line(startProductsTableX, startProductsTableY, startProductsTableX, currentLine - 1.5)
                 
           
                currentLine += 1.5



            }

            const sale = totalSalesByProductId[productId]
            currentLine += 2.5
            doc.text(`${sale?.product?.name?.length > 25 ? sale?.product?.name?.substring(0, 26) + "..." : sale?.product?.name}`, startProductsTableX + 2, currentLine)
            console.log(sale);
            doc.text(`R$ ${formatMonetaryValue((sale?.product?.purchaseCost * 100).toFixed(2))}`, buyPriceLabelPosition, currentLine)
            doc.text(`R$ ${formatMonetaryValue((sale?.product?.otherCosts * 100).toFixed(2))}`, costsLabelPosition, currentLine)
            doc.text(`R$ ${formatMonetaryValue((sale?.product?.price * 100).toFixed(2))}`, salePriceLabelPosition, currentLine)
            doc.text(`${sale?.amount}`, amountlabelPosition, currentLine)

            const profitValue = ((sale?.product?.price * sale?.amount) - (sale?.product?.purchaseCost * sale?.amount) - sale?.product?.otherCosts)
            totalProfit += profitValue || 0
            if (profitValue < 0)
                doc.setTextColor("#d00000")
            else
                doc.setTextColor("#00ab00")
               
            doc.text(`R$ ${formatMonetaryValue(((profitValue) * 100).toFixed(2))}`, profitLabelPosition, currentLine)

            doc.setTextColor("#000000")
             
            currentLine += 1.5
            doc.line(startProductsTableX, currentLine, endTableX, currentLine)
            currentLine += 1.5
        }

        doc.line(startProductsTableX, startProductsTableY, startProductsTableX, currentLine - 1.5)
         
        doc.line(buyPriceLabelPosition - 3, startProductsTableY, buyPriceLabelPosition - 3, currentLine - 1.5)
        doc.line(salePriceLabelPosition - 3, startProductsTableY, salePriceLabelPosition - 3, currentLine - 1.5)
        doc.line(costsLabelPosition - 3, startProductsTableY, costsLabelPosition - 3, currentLine - 1.5)
        doc.line(amountlabelPosition - 3, startProductsTableY, amountlabelPosition - 3, currentLine - 1.5)
        doc.line(profitLabelPosition - 3, startProductsTableY, profitLabelPosition - 3, currentLine - 1.5)
               
        doc.line(endTableX, startProductsTableY, endTableX, currentLine - 1.5)
        currentLine += 2.5
        if (totalProfit < 0)
            doc.setTextColor("#d00000")
        else
            doc.setTextColor("#00ab00")
            doc.setFont("helvetica", "bold");
        doc.text(`R$ ${formatMonetaryValue(((totalProfit) * 100).toFixed(2))}`, profitLabelPosition, currentLine)
        doc.setTextColor("#000000")
        doc.setFont("helvetica", "normal");

        //////////////// items vendidos
        ///////////////////////////////////////////////////////////////////////////////////


        //////////////// items devolvidos

        if ((currentLine + 50) / 280 > 1) {
            doc.addPage()

            currentLine = 10
        }

        currentLine += 15
        doc.setFont("helvetica", "bold");
        doc.setFontSize(12)



        doc.text(`Devoluções de Produtos/serviços do período de ${dayjs(startDate).format("DD/MM/YYYY")} a ${endDate ? dayjs(endDate).format("DD/MM/YYYY") : dayjs().format("DD/MM/YYYY")}`, 33, currentLine);

        doc.setFontSize(10)
        currentLine += 10
        let startReturnProductsTableX = 20
        let startReturnProductsTableY = currentLine - 5;

        doc.line(startReturnProductsTableX, startReturnProductsTableY, endTableX, startReturnProductsTableY)
        doc.text("Produto/serviço", startReturnProductsTableX + 2, currentLine)
        doc.text("Quantidade", startReturnProductsTableX + 145, currentLine)

        currentLine += 2
        doc.line(startReturnProductsTableX, currentLine, endTableX, currentLine)
        doc.setFont("helvetica", "normal");
        currentLine += 1.5
        for (const productId in totalReturnsByproductId) {

            if ((currentLine + 20) / 280 > 1) {

                doc.line(startReturnProductsTableX, startReturnProductsTableY, startReturnProductsTableX, currentLine - 1.5)
                doc.line(+157, startReturnProductsTableY, +157, currentLine - 1.5)
                doc.line(endTableX, startReturnProductsTableY, endTableX, currentLine - 1.5)

                doc.addPage()

                currentLine = 10
                startReturnProductsTableY = currentLine - 5;

                doc.line(startReturnProductsTableX, startReturnProductsTableY, endTableX, startReturnProductsTableY)
                doc.setFont("helvetica", "bold");
                doc.text("Produto/serviço", startReturnProductsTableX + 2, currentLine)
                doc.text("Quantidade", startReturnProductsTableX + 145, currentLine)

                currentLine += 2
                doc.line(startReturnProductsTableX, currentLine, endTableX, currentLine)
                doc.setFont("helvetica", "normal");
                currentLine += 1.5



            }

            const sale = totalReturnsByproductId[productId]
            currentLine += 2.5
            doc.text(`${sale?.product?.name}`, startReturnProductsTableX + 2, currentLine)

            doc.text(`${sale?.amount}`, startReturnProductsTableX + 152, currentLine)
            currentLine += 1.5
            doc.line(startReturnProductsTableX, currentLine, endTableX, currentLine)
            currentLine += 1.5
        }

        doc.line(startReturnProductsTableX, startReturnProductsTableY, startReturnProductsTableX, currentLine - 1.5)
        doc.line(+157, startReturnProductsTableY, +157, currentLine - 1.5)
        doc.line(endTableX, startReturnProductsTableY, endTableX, currentLine - 1.5)



        //////////////// items devolvidos
        ///////////////////////////////////////////////////////////////////////////////////
        doc.addPage()
    }

    startingFinanceTransactionsLine = 15
    doc.setFont("helvetica", "bold");
    doc.setFontSize(12)
    if (finances?.length > 1)
        doc.text(`Balanço por caixa do período de ${dayjs(startDate).format("DD/MM/YYYY")} a ${endDate ? dayjs(endDate).format("DD/MM/YYYY") : dayjs().format("DD/MM/YYYY")}`, 50, startingFinanceTransactionsLine - 5);


    /// end generate finance total sales and returns


    //////////////////
    function generateFinancePdfSection(finance, startingLine) {
        let currentLine = startingLine
        let currentPageCount = 1;

        if ((currentLine + 85) / 280 > 1) {

            doc.addPage()
            currentPageCount++
            currentLine = 10


        }

        doc.line(0, currentLine, 210, currentLine)
        currentLine += .25
        doc.line(0, currentLine, 210, currentLine)
        currentLine += .25
        doc.line(0, currentLine, 210, currentLine)

        currentLine += 10

        doc.setFont("helvetica", "bold");
        doc.setFontSize(14);
        doc.text(`Caixa Nº ${finance.id} `, 2, currentLine);
        doc.setFont("helvetica", "normal");
        doc.setFontSize(12);
        doc.text(`Abertura...............${dayjs(finance.startDate).format("DD/MM/YY - HH:mm")} `, 140, currentLine)
        currentLine += 5
        doc.text(`Fechamento.........${finance.endDate ? dayjs(finance.endDate).format("DD/MM/YY - HH:mm") : "Aberto"} `, 140, currentLine)
        currentLine += 5

        doc.text(`Valor inicial: R$ ${formatMonetaryValue((finance.initialMoney * 100).toFixed(2))} `, 2, currentLine)


        currentLine += 10
        const startingbalanceTableY = currentLine - 5;
        const leftStartBalanceTable = 28

        const moneyTablestart = leftStartBalanceTable + 22
        const pixTablestart = leftStartBalanceTable + 56
        const cardTablestart = leftStartBalanceTable + 87
        const totalTablestart = leftStartBalanceTable + 120

        doc.text(` Dinheiro `, moneyTablestart, currentLine);
        doc.text(` Pix `, pixTablestart, currentLine);
        doc.text(` Cartão `, cardTablestart, currentLine);
        doc.text(` Total `, totalTablestart, currentLine);

        doc.line(leftStartBalanceTable - 2, currentLine + 1.5, 181, currentLine + 1.5)

        currentLine += 7
        doc.text(`Entradas`, leftStartBalanceTable, currentLine);
        doc.setTextColor("#00ab00")
        const totalMoneyIncome = finance.transactions?.reduce((acc, curr) => curr.type === "Income" ? acc + curr.moneyValue : acc, 0) * 100 || 0
        const totalCardIncome = finance.transactions?.reduce((acc, curr) => curr.type === "Income" ? acc + curr.cardValue : acc, 0) * 100 || 0
        totalCardIncomeBalance += totalCardIncome

        const totalPixIncome = finance.transactions?.reduce((acc, curr) => curr.type === "Income" ? acc + curr.pixValue : acc, 0) * 100 || 0
        totalPixIncomeBalance += totalPixIncome

        const totalChangeMoney = finance.transactions?.reduce((acc, curr) => curr.category === "Venda" ? acc + (curr.moneyValue > 0 ? (curr.moneyValue - (curr.totalValue - curr.pixValue - curr.cardValue)) : 0) : acc, 0)?.toFixed(2) * 100
        totalMoneyIncomeBalance += totalMoneyIncome - totalChangeMoney

        const totalIncomeValue = (totalCardIncome + totalMoneyIncome + totalPixIncome - totalChangeMoney).toFixed(2)
        // doc.text(`R$ ${formatMonetaryValue(totalIncomeValue)}`, 38, currentLine);

        doc.setTextColor("#00ab00")
        //
        doc.text(`R$ ${formatMonetaryValue((totalMoneyIncome - totalChangeMoney).toFixed(2))} `, moneyTablestart, currentLine);

        doc.setTextColor("#00ab00")
        doc.text(`R$ ${formatMonetaryValue(totalPixIncome)} `, pixTablestart, currentLine);

        doc.setTextColor("#00ab00")
        doc.text(`R$ ${formatMonetaryValue(totalCardIncome)} `, cardTablestart, currentLine);

        doc.setTextColor("#00ab00")
        doc.text(`R$ ${formatMonetaryValue(totalIncomeValue)} `, totalTablestart, currentLine);

        doc.line(leftStartBalanceTable - 2, currentLine + 1.5, 181, currentLine + 1.5)

        currentLine += 7
        doc.setTextColor("#000000")
        doc.text(`Saídas`, leftStartBalanceTable + 2, currentLine);
        doc.setTextColor("#d00000")
        const totalOutcomeValue = finance.transactions?.reduce((acc, curr) => curr.type === "Outcome" ? acc + curr.totalValue : acc, 0) * 100 || 0
        // doc.text(`R$ ${formatMonetaryValue(totalOutcomeValue.toFixed(2))}`, 33, currentLine);

        doc.setTextColor("#000000")

        const totalMoneyOutcome = finance.transactions?.reduce((acc, curr) => curr.type === "Outcome" ? acc + curr.moneyValue : acc, 0) * 100 || 0
        totalMoneyOutcomeBalance += totalMoneyOutcome

        const totalCardOutcome = finance.transactions?.reduce((acc, curr) => curr.type === "Outcome" ? acc + curr.cardValue : acc, 0) * 100 || 0
        totalCardOutcomeBalance += totalCardOutcome

        const totalPixOutcome = finance.transactions?.reduce((acc, curr) => curr.type === "Outcome" ? acc + curr.pixValue : acc, 0) * 100 || 0
        totalPixOutcomeBalance += totalPixOutcome

        doc.setTextColor("#d00000")
        doc.text(`R$ ${formatMonetaryValue((totalMoneyOutcome).toFixed(2))} `, moneyTablestart, currentLine);

        doc.text(`R$ ${formatMonetaryValue(totalPixOutcome)} `, pixTablestart, currentLine);

        doc.text(`R$ ${formatMonetaryValue(totalCardOutcome)} `, cardTablestart, currentLine);

        doc.text(`R$ ${formatMonetaryValue(totalOutcomeValue)} `, totalTablestart, currentLine);

        doc.line(leftStartBalanceTable - 2, currentLine + 1.5, 181, currentLine + 1.5)

        currentLine += 7
        doc.setTextColor("#000000")
        doc.text(`Saldo`, leftStartBalanceTable + 3.5, currentLine);
        doc.setTextColor("#d00000")

        doc.setTextColor("#000000")


        const totalBalance = (totalIncomeValue - totalOutcomeValue).toFixed(2)
        doc.setTextColor(totalBalance < 0 ? "#d00000" : "#00ab00")
        //doc.text(`R$ ${formatMonetaryValue(totalBalance)}`, 33, currentLine);

        doc.setTextColor("#000000")

        const moneyBalance = totalMoneyIncome - totalChangeMoney - totalMoneyOutcome
        doc.setTextColor(moneyBalance < 0 ? "#d00000" : "#00ab00")
        doc.text(`R$ ${formatMonetaryValue((moneyBalance).toFixed(2))} `, moneyTablestart, currentLine);

        doc.setTextColor("#000000")

        const pixBalance = totalPixIncome - totalPixOutcome
        doc.setTextColor(pixBalance < 0 ? "#d00000" : "#00ab00")
        doc.text(`R$ ${formatMonetaryValue((pixBalance).toFixed(2))} `, pixTablestart, currentLine);

        doc.setTextColor("#000000")

        const cardBalance = totalCardIncome - totalCardOutcome
        doc.setTextColor(cardBalance < 0 ? "#d00000" : "#00ab00")
        doc.text(`R$ ${formatMonetaryValue((cardBalance).toFixed(2))} `, cardTablestart, currentLine);

        doc.setTextColor(totalBalance < 0 ? "#d00000" : "#00ab00")
        doc.text(`R$ ${formatMonetaryValue((totalBalance))} `, totalTablestart, currentLine);


        currentLine += 1.5
        doc.line(leftStartBalanceTable - 2, startingbalanceTableY, 181, startingbalanceTableY)
        doc.line(leftStartBalanceTable - 2, currentLine, leftStartBalanceTable - 2, startingbalanceTableY)
        doc.line(181, currentLine, 181, startingbalanceTableY)
        doc.line(moneyTablestart - 2, currentLine, moneyTablestart - 2, startingbalanceTableY)
        doc.line(pixTablestart - 2, currentLine, pixTablestart - 2, startingbalanceTableY)
        doc.line(cardTablestart - 2, currentLine, cardTablestart - 2, startingbalanceTableY)
        doc.line(totalTablestart - 2, currentLine, totalTablestart - 2, startingbalanceTableY)
        doc.line(leftStartBalanceTable - 2, currentLine, 181, currentLine)




        currentLine += 10


        doc.setFont("helvetica", "bold");
        doc.setTextColor("#000000")
        doc.setFontSize(12)

        doc.text(`Registros`, 90, currentLine);

        currentLine += 3


        doc.line(10, currentLine, 200, currentLine)
        let startTableY = currentLine;

        // Data/hora	Tipo	Categoria	Quantidade	Valor total	Dinheiro	Pix	Cartão	Troco
        currentLine += 5

        doc.setFont("helvetica", "bold");

        doc.setTextColor("#000000")
        doc.text(`Data/hora`, 15, currentLine);
        doc.text(`Tipo`, 40, currentLine);
        doc.text(`Valor total`, 60, currentLine);
        doc.text(`Dinheiro`, 85, currentLine);
        doc.text(`Pix`, 110, currentLine);
        doc.text(`Cartão`, 135, currentLine);
        doc.text(`Troco`, 165, currentLine);

        currentLine += 2
        doc.line(10, currentLine, 200, currentLine)

        ////////////////////////////  criação dinâmica transações/////////////////////////////////////////////////////

        const SalesByProductId = {}
        const returnsByproductId = {}

        finance?.transactions?.forEach((currTransaction) => {

            currentLine += 5
            if ((currentLine + 15 + currTransaction.orders.length * 7) > 290) {
                const endTableY = currentLine - 5
                doc.line(10, startTableY, 10, endTableY)
                doc.line(200, startTableY, 200, endTableY)

                doc.addPage()
                currentPageCount++
                currentLine = 10
                startTableY = currentLine - 5
                doc.line(10, currentLine - 5, 200, currentLine - 5)
                doc.setFont("helvetica", "bold");

                doc.setTextColor("#000000")
                doc.text(`Data/hora`, 15, currentLine);
                doc.text(`Tipo`, 40, currentLine);
                doc.text(`Valor total`, 60, currentLine);
                doc.text(`Dinheiro`, 85, currentLine);
                doc.text(`Pix`, 110, currentLine);
                doc.text(`Cartão`, 135, currentLine);
                doc.text(`Troco`, 165, currentLine);

                currentLine += 2
                doc.line(10, currentLine, 200, currentLine)
                currentLine += 5

            }

            doc.setFont("helvetica", "normal");
            doc.setTextColor("#000000")
            doc.setFontSize(10);
            doc.text(`${currTransaction.createdAt === "0001-01-01T00:00:00" ? dayjs(finance.startDate).format("DD/MM - HH:mm") : dayjs(currTransaction.createdAt).format("DD/MM - HH:mm")}`, 15, currentLine);

            doc.setFont("helvetica", "bold");
            currTransaction.type === "Income" ? doc.setTextColor("#00ab00") : doc.setTextColor("#d00000")
            doc.text(`${currTransaction.type === "Income" ? "Entrada" : "Saída"}`, 40, currentLine);
            doc.text(`R$ ${formatMonetaryValue((currTransaction.totalValue * 100).toFixed(2))}`, 60, currentLine);
            doc.setFont("helvetica", "normal");
            doc.setTextColor("#000000")
            doc.text(`R$ ${formatMonetaryValue((currTransaction.moneyValue * 100).toFixed(2))}`, 85, currentLine);
            doc.text(`R$ ${formatMonetaryValue((currTransaction.pixValue * 100).toFixed(2))}`, 110, currentLine);
            doc.text(`R$ ${formatMonetaryValue((currTransaction.cardValue * 100).toFixed(2))}`, 135, currentLine);

            const changeMoney = currTransaction.moneyValue > 0 ? currTransaction.moneyValue - (currTransaction.totalValue - currTransaction.pixValue - currTransaction.cardValue) : 0

            doc.text(currTransaction.category === "Venda" ? `R$ ${formatMonetaryValue((changeMoney * 100).toFixed(2))}` : `Interno/${currTransaction.category}`, 165, currentLine);

            currentLine += 2
            for (let i = 12; i < 198; i += 4) {
                doc.line(i, currentLine, i + 2, currentLine)
            }

            if (currTransaction.category === "Venda") {
                const orders = currTransaction.orders

                orders.forEach(o => {
                    const productId = o.product.id
                    if (SalesByProductId[productId] === undefined) {
                        SalesByProductId[productId] = { ...o }
                    } else {
                        SalesByProductId[productId].amount = SalesByProductId[productId].amount + o.amount
                    }


                })
            }


            if (currTransaction.category === "Devolucao") {
                const orders = currTransaction.orders

                orders.forEach(o => {
                    const productId = o.product.id
                    if (SalesByProductId[productId] === undefined) {
                        SalesByProductId[productId] = { ...o }
                        SalesByProductId[productId].amount = -1 * SalesByProductId[productId].amount
                    } else {
                        SalesByProductId[productId].amount = SalesByProductId[productId].amount - o.amount
                    }

                    if (returnsByproductId[productId] === undefined) {
                        returnsByproductId[productId] = { ...o }
                    } else {
                        returnsByproductId[productId].amount = returnsByproductId[productId].amount + o.amount
                    }

                })
            }

            //////////////////////////////////  criação dinamica pedidos///////////////////////////////////////////////////////////           ////////////////////////////////////
            if ((currTransaction.category === "Venda" || currTransaction.category === "Devolucao") && shouldDetailOrders) {

                currentLine += 4

                if (currentLine / 280 > 1) {
                    const endTableY = currentLine - 4
                    doc.line(10, startTableY, 10, endTableY)
                    doc.line(200, startTableY, 200, endTableY)

                    doc.addPage()
                    currentPageCount++
                    currentLine = 10
                    startTableY = currentLine - 4

                }

                doc.setFont("helvetica", "bold");

                doc.setTextColor("#000000")
                doc.text(`Produto/serviço`, 50, currentLine);
                doc.text(`Quantidade`, 140, currentLine);
                doc.text(`Valor unitário`, 170, currentLine);
                currentLine += 2

                currTransaction.orders.forEach(currentOrder => {
                    const currentProduct = currentOrder.product

                    for (let i = 48; i < 198; i += 4) {
                        doc.line(i, currentLine, i + 2, currentLine)
                    }
                    doc.line(48, currentLine - 2, 48, currentLine - 4)

                    currentLine += 5
                    doc.setFont("helvetica", "normal");
                    doc.text(`${currentProduct.name}`, 50, currentLine);
                    doc.text(`${currentOrder.amount}`, 150, currentLine);
                    doc.text(`R$ ${formatMonetaryValue((currentProduct.price * 100).toFixed(2))}`, 175, currentLine);

                    currentLine += 2
                    for (let i = 48; i < 198; i += 4) {
                        doc.line(i, currentLine, i + 2, currentLine)
                    }
                    doc.line(48, currentLine - 2, 48, currentLine - 4)


                })
            }


            doc.line(10, currentLine, 200, currentLine)




        })

        const endTableY = currentLine
        doc.line(10, startTableY, 10, endTableY)
        doc.line(200, startTableY, 200, endTableY)

        //////////////// items vendidos

        if ((currentLine + 50) / 280 > 1) {
            doc.addPage()
            currentPageCount++
            currentLine = 10
        }

        currentLine += 15
        doc.setFont("helvetica", "bold");
        doc.setFontSize(12)



        doc.text(`Vendas consolidadas de Produtos/serviços`, 65, currentLine);

        doc.setFontSize(10)
        currentLine += 10
        let startProductsTableX = 20
        let startProductsTableY = currentLine - 5;

        doc.line(startProductsTableX, startProductsTableY, 192, startProductsTableY)
        doc.text("Produto/serviço", startProductsTableX + 2, currentLine)
        doc.text("Quantidade", startProductsTableX + 145, currentLine)

        currentLine += 2
        doc.line(startProductsTableX, currentLine, 192, currentLine)
        doc.setFont("helvetica", "normal");
        currentLine += 1.5
        for (const productId in SalesByProductId) {

            if ((currentLine + 20) / 280 > 1) {

                doc.line(startProductsTableX, startProductsTableY, startProductsTableX, currentLine - 1.5)
                doc.line(+157, startProductsTableY, +157, currentLine - 1.5)
                doc.line(192, startProductsTableY, 192, currentLine - 1.5)

                doc.addPage()
                currentPageCount++
                currentLine = 10
                startProductsTableY = currentLine - 5;

                doc.line(startProductsTableX, startProductsTableY, 192, startProductsTableY)
                doc.setFont("helvetica", "bold");
                doc.text("Produto/serviço", startProductsTableX + 2, currentLine)
                doc.text("Quantidade", startProductsTableX + 145, currentLine)

                currentLine += 2
                doc.line(startProductsTableX, currentLine, 192, currentLine)
                doc.setFont("helvetica", "normal");
                currentLine += 1.5



            }

            const sale = SalesByProductId[productId]
            currentLine += 2.5
            doc.text(`${sale?.product?.name}`, startProductsTableX + 2, currentLine)

            doc.text(`${sale?.amount}`, startProductsTableX + 152, currentLine)
            currentLine += 1.5
            doc.line(startProductsTableX, currentLine, 192, currentLine)
            currentLine += 1.5
        }

        doc.line(startProductsTableX, startProductsTableY, startProductsTableX, currentLine - 1.5)
        doc.line(+157, startProductsTableY, +157, currentLine - 1.5)
        doc.line(192, startProductsTableY, 192, currentLine - 1.5)



        //////////////// items vendidos
        ///////////////////////////////////////////////////////////////////////////////////


        //////////////// items devolvidos

        if ((currentLine + 50) / 280 > 1) {
            doc.addPage()
            currentPageCount++
            currentLine = 10
        }

        currentLine += 15
        doc.setFont("helvetica", "bold");
        doc.setFontSize(12)



        doc.text(`Devoluções de Produtos/serviços`, 75, currentLine);

        doc.setFontSize(10)
        currentLine += 10
        let startReturnProductsTableX = 20
        let startReturnProductsTableY = currentLine - 5;

        doc.line(startReturnProductsTableX, startReturnProductsTableY, 192, startReturnProductsTableY)
        doc.text("Produto/serviço", startReturnProductsTableX + 2, currentLine)
        doc.text("Quantidade", startReturnProductsTableX + 145, currentLine)

        currentLine += 2
        doc.line(startReturnProductsTableX, currentLine, 192, currentLine)
        doc.setFont("helvetica", "normal");
        currentLine += 1.5
        for (const productId in returnsByproductId) {

            if ((currentLine + 20) / 280 > 1) {

                doc.line(startReturnProductsTableX, startReturnProductsTableY, startReturnProductsTableX, currentLine - 1.5)
                doc.line(+157, startReturnProductsTableY, +157, currentLine - 1.5)
                doc.line(192, startReturnProductsTableY, 192, currentLine - 1.5)

                doc.addPage()
                currentPageCount++
                currentLine = 10
                startReturnProductsTableY = currentLine - 5;

                doc.line(startReturnProductsTableX, startReturnProductsTableY, 192, startReturnProductsTableY)
                doc.setFont("helvetica", "bold");
                doc.text("Produto/serviço", startReturnProductsTableX + 2, currentLine)
                doc.text("Quantidade", startReturnProductsTableX + 145, currentLine)

                currentLine += 2
                doc.line(startReturnProductsTableX, currentLine, 192, currentLine)
                doc.setFont("helvetica", "normal");
                currentLine += 1.5



            }

            const sale = returnsByproductId[productId]
            currentLine += 2.5
            doc.text(`${sale?.product?.name}`, startReturnProductsTableX + 2, currentLine)

            doc.text(`${sale?.amount}`, startReturnProductsTableX + 152, currentLine)
            currentLine += 1.5
            doc.line(startReturnProductsTableX, currentLine, 192, currentLine)
            currentLine += 1.5
        }

        doc.line(startReturnProductsTableX, startReturnProductsTableY, startReturnProductsTableX, currentLine - 1.5)
        doc.line(+157, startReturnProductsTableY, +157, currentLine - 1.5)
        doc.line(192, startReturnProductsTableY, 192, currentLine - 1.5)



        //////////////// items devolvidos
        ///////////////////////////////////////////////////////////////////////////////////



        currentLine += 10
        for (let i = 0; i < 210; i += 4) {
            if (i > 85 && i < 120)
                continue

            doc.line(i, currentLine, i + 2, currentLine)
        }

        currentLine += 1
        doc.setFont("helvetica", "bold");



        doc.text(`Fim caixa Nº ${finance.id}`, 90, currentLine);


        return currentLine;
    }


    finances && finances.forEach(finance => {

        const finalLine = generateFinancePdfSection(finance, startingFinanceTransactionsLine + 5)
        startingFinanceTransactionsLine = finalLine
    })

    if (finances?.length > 1) {
        doc.setPage(1)
        let currentLine = 52
        doc.text(`Balanço total do período de ${dayjs(startDate).format("DD/MM/YYYY")} a ${endDate ? dayjs(endDate).format("DD/MM/YYYY") : dayjs().format("DD/MM/YYYY")}`, 60, currentLine - 6);

        const startingbalanceTableY = currentLine - 5;
        const leftStartBalanceTable = 28

        const moneyTablestart = leftStartBalanceTable + 22
        const pixTablestart = leftStartBalanceTable + 56
        const cardTablestart = leftStartBalanceTable + 87
        const totalTablestart = leftStartBalanceTable + 120

        doc.text(` Dinheiro `, moneyTablestart, currentLine);
        doc.text(` Pix `, pixTablestart, currentLine);
        doc.text(` Cartão `, cardTablestart, currentLine);
        doc.text(` Total `, totalTablestart, currentLine);

        doc.line(leftStartBalanceTable - 2, currentLine + 1.5, 181, currentLine + 1.5)

        currentLine += 7

        doc.setTextColor("#00ab00")
        //
        doc.text(`R$ ${formatMonetaryValue((totalMoneyIncomeBalance).toFixed(2))} `, moneyTablestart, currentLine);

        doc.setTextColor("#00ab00")
        doc.text(`R$ ${formatMonetaryValue(totalPixIncomeBalance)} `, pixTablestart, currentLine);

        doc.setTextColor("#00ab00")
        doc.text(`R$ ${formatMonetaryValue(totalCardIncomeBalance)} `, cardTablestart, currentLine);

        doc.setTextColor("#00ab00")
        const totalIncomeBalanceValue = (totalMoneyIncomeBalance + totalPixIncomeBalance + totalCardIncomeBalance).toFixed(2)
        doc.text(`R$ ${formatMonetaryValue(totalIncomeBalanceValue)} `, totalTablestart, currentLine);

        doc.line(leftStartBalanceTable - 2, currentLine + 1.5, 181, currentLine + 1.5)

        currentLine += 7
        doc.setTextColor("#000000")
        doc.text(`Saídas`, leftStartBalanceTable + 2, currentLine);
        doc.setTextColor("#d00000")

        doc.setTextColor("#000000")


        doc.setTextColor("#d00000")
        doc.text(`R$ ${formatMonetaryValue((totalMoneyOutcomeBalance).toFixed(2))} `, moneyTablestart, currentLine);

        doc.text(`R$ ${formatMonetaryValue(totalPixOutcomeBalance)} `, pixTablestart, currentLine);

        doc.text(`R$ ${formatMonetaryValue(totalCardOutcomeBalance)} `, cardTablestart, currentLine);

        const totalOutcomeBalanceValue = (totalMoneyOutcomeBalance + totalPixOutcomeBalance + totalCardOutcomeBalance).toFixed(2)
        doc.text(`R$ ${formatMonetaryValue(totalOutcomeBalanceValue)} `, totalTablestart, currentLine);

        doc.line(leftStartBalanceTable - 2, currentLine + 1.5, 181, currentLine + 1.5)

        currentLine += 7
        doc.setTextColor("#000000")
        doc.text(`Saldo`, leftStartBalanceTable + 3.5, currentLine);
        doc.setTextColor("#d00000")

        doc.setTextColor("#000000")


        const totalBalance = (totalIncomeBalanceValue - totalOutcomeBalanceValue).toFixed(2)
        doc.setTextColor(totalBalance < 0 ? "#d00000" : "#00ab00")
        //doc.text(`R$ ${formatMonetaryValue(totalBalance)}`, 33, currentLine);

        doc.setTextColor("#000000")

        const moneyBalance = totalMoneyIncomeBalance - totalMoneyOutcomeBalance
        doc.setTextColor(moneyBalance < 0 ? "#d00000" : "#00ab00")
        doc.text(`R$ ${formatMonetaryValue((moneyBalance).toFixed(2))} `, moneyTablestart, currentLine);

        doc.setTextColor("#000000")

        const pixBalance = totalPixIncomeBalance - totalPixOutcomeBalance
        doc.setTextColor(pixBalance < 0 ? "#d00000" : "#00ab00")
        doc.text(`R$ ${formatMonetaryValue((pixBalance).toFixed(2))} `, pixTablestart, currentLine);

        doc.setTextColor("#000000")

        const cardBalance = totalCardIncomeBalance - totalCardOutcomeBalance
        doc.setTextColor(cardBalance < 0 ? "#d00000" : "#00ab00")
        doc.text(`R$ ${formatMonetaryValue((cardBalance).toFixed(2))} `, cardTablestart, currentLine);

        doc.setTextColor(totalBalance < 0 ? "#d00000" : "#00ab00")
        doc.text(`R$ ${formatMonetaryValue((totalBalance))} `, totalTablestart, currentLine);


        currentLine += 1.5
        doc.line(leftStartBalanceTable - 2, startingbalanceTableY, 181, startingbalanceTableY)
        doc.line(leftStartBalanceTable - 2, currentLine, leftStartBalanceTable - 2, startingbalanceTableY)
        doc.line(181, currentLine, 181, startingbalanceTableY)
        doc.line(moneyTablestart - 2, currentLine, moneyTablestart - 2, startingbalanceTableY)
        doc.line(pixTablestart - 2, currentLine, pixTablestart - 2, startingbalanceTableY)
        doc.line(cardTablestart - 2, currentLine, cardTablestart - 2, startingbalanceTableY)
        doc.line(totalTablestart - 2, currentLine, totalTablestart - 2, startingbalanceTableY)
        doc.line(leftStartBalanceTable - 2, currentLine, 181, currentLine)


        currentLine += 10
        doc.setTextColor("#000000")

    }



    if (!shouldSave) {
        const pdfData = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfData);
        window.open(pdfUrl, '_blank');
        return
    }

    doc.save(`Relatório caixa de ${dayjs(startDate).format("DD/MM/YYYY")} - ${endDate ? dayjs(endDate).format("DD/MM/YYYY") : dayjs().format("DD/MM/YYYY")}`)


}

