import styled from "styled-components"
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from "react-router-dom"
import {useState, useEffect} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Menagement from "./menagement";
import Analysis from "./analysis";
import FinanceDetails from "./analysis/FinanceDetails";
 

export default function FinancePage() {
    const [tabValue, setTabValue] = useState(0)
   const nav = useNavigate();

    const {pathname} = useLocation()
    const tabPathnameValues = ["/caixa/gerenciar", "/caixa/registros"]
 
    useEffect(()=>{
        tabPathnameValues.forEach((t,i) => {if(pathname.includes(t)) setTabValue(i)})
         
    }, [pathname])


    return (
        <Container>
            <Tabs value={tabValue}  aria-label="disabled tabs example">
                <Tab label="Gerenciar" onClick={() => nav("/caixa/gerenciar")}/>
                <Tab label="Registros" onClick={() => nav("/caixa/registros")}/>   
            </Tabs>
            <hr/>
            
            <Routes>
                <Route path="/gerenciar" element={<Menagement/>} />
                <Route path="/registros" element={ <Analysis/>} />
                <Route path="/registros/:id" element={ <FinanceDetails/>} />
            </Routes>
        </Container>
    )
}

const Container = styled.div`
    [role="tablist"]{
        display: flex;
        justify-content: center;
        
        padding-top: 1rem;

        button:first-child{
            margin-right: 1rem;
        }

        button{
            width: 50%;
            font-weight: bold;
            font-size: 1rem;
        }

        button:hover{
             
            background-color: rgba(150, 150, 150, .1);;
        }
    }

    hr{
        margin: 1rem;
    }
`
 