import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import warningMessage from "../../../helpers/warnMessage";
import Financestable from "./financesTable";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


import styled from "styled-components";
import dayjs from "dayjs";
import ptBR from 'date-fns/locale/pt-BR';
import Loader from "../../../Components/SideMenu/Loader";

import getFinancePdf from "../../../helpers/getFinancePdf.js";

import { VscFilePdf as PdfIcon } from "react-icons/vsc"
import { TbFileDownload as DownloadIcon } from "react-icons/tb"
import Swal from "sweetalert2";

registerLocale('pt-BR', ptBR)

export default function Analysis() {
    const [finances, setFinances] = useState([])
    const { user } = useContext(AuthContext);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [filteredFinances, setFilteredFinances] = useState(finances)

    const [isGeneratingPdf, setIsGeneratingPdf] = useState(false)


    const [isFinancesLoading, setIsFinancesLoading] = useState(true)

    useEffect(() => {


        const promise = filterFinaces()

        promise.then(() => {

        })
            .catch(() => {
                warningMessage("Erro inesperado")

                setFilteredFinances(finances)
            })
    }, [startDate, endDate, finances]
    )

    useEffect(() => { getFinances() }, [])

    useEffect(() => {
        if (finances.length === 0)
            return;

        setStartDate(new Date(dayjs(finances[finances.length - 1].startDate).format("MM-DD-YYYY")))
        setEndDate(new Date(dayjs(finances[0].startDate).format("MM-DD-YYYY")))
    }, [finances])

    return (
        <div>
            <DateSelectionHolder >
                <div >
                    <p>De: </p> <DatePicker locale={ptBR} value={dayjs(startDate).format("DD-MM-YYYY")} selected={startDate} width="10rem" onChange={(date) => setStartDate(date)} /> <p>até:</p>  <DatePicker locale="pt-BR" value={dayjs(endDate).format("DD-MM-YYYY")} selected={startDate} onChange={(date) => setEndDate(date)} />
                    <div className="get-pdf-menu">
                        {isGeneratingPdf
                            ?   <Loader width={"2rem"} height="2rem" /> 
                            :
                            <>
                                <PdfIcon onClick={() => handleGetFinanceDetailsPdf(false)} />
                                <DownloadIcon onClick={() => handleGetFinanceDetailsPdf(true)} />
                            </>}
                    </div>
                </div>
            </DateSelectionHolder>
            {isFinancesLoading ? <LoaderContainer> <Loader width={"8rem"} height="8rem" /></LoaderContainer> : <Financestable finances={filteredFinances} />}

        </div>
    )

    function handleGetFinanceDetailsPdf(shouldDownload) {
        Swal.fire({
            title: 'Detalhar produtos em cada pedido?',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            denyButtonText: `Não`,
            cancelButtonText: "Voltar"
        }).then((result) => {
            // Read more about isConfirmed, isDenied below 

            if (result.isConfirmed) {
                setIsGeneratingPdf(true)
                getFinances(startDate, endDate).then(financesInDateRange => {
                    getFinancePdf(financesInDateRange, startDate, endDate, shouldDownload, true)
                    setIsGeneratingPdf(false)
                }).catch(e => {
                    setIsGeneratingPdf(false)
                    warningMessage("Erro inesperado")
                })
            } else if (result.isDenied) {
                setIsGeneratingPdf(true)
                getFinances(startDate, endDate).then(financesInDateRange => {
                    getFinancePdf(financesInDateRange, startDate, endDate, shouldDownload, false)
                    setIsGeneratingPdf(false)
                }).catch(e => {
                    setIsGeneratingPdf(false)
                    warningMessage("Erro inesperado")
                })
            }

        })
    }

    async function getFinances(startDate, endDate) {
        const queryParams = (!!startDate && !!endDate) ? `?startDate=${dayjs(startDate).format()}&endDate=${dayjs(endDate).format()}` : ""
        try {
            const result = await axios.get(`https://${user.ip}/api/finance${queryParams}`, { headers: { Authorization: `Bearer ${user.token}` } })

            if (!!startDate && !!endDate)
                return result.data

            setFinances(result.data);
            setIsFinancesLoading(false)
        } catch (err) {
            if (!!startDate && !!endDate)
                throw err

            setFinances([]);
            setIsFinancesLoading(false)

            if (err?.response?.status === 401)
                return warningMessage("Acesso não autorizado")

            warningMessage("Erro inesperado")
        }
    }

    async function filterFinaces() {
        if (!startDate || !endDate)
            return setFilteredFinances(finances)

        setFilteredFinances(finances.filter(f => {
            const fomatedStartDate = dayjs(f.startDate).format("MM-DD-YYYY");

            return ((dayjs(fomatedStartDate).isAfter(startDate) || dayjs(fomatedStartDate).isSame(startDate)) && (dayjs(fomatedStartDate).isBefore(endDate) || dayjs(fomatedStartDate).isSame(endDate)))

        })

        )
    }
}


const DateSelectionHolder = styled.div`
position: relative;


& > div {
width: 20rem;
display: flex;

justify-content: space-between;
align-items: center;

 ;
    z-index:10; 
    position: absolute;
    left: calc(40vw - 8rem);
    top: 0;


p{
    margin-left: 1rem;
    margin-right: .5rem;
}

input{
    width: 6rem;
    font-size: .75rem;
}
}

.get-pdf-menu{

display: flex;
width: 15rem;
 

svg{
    font-size: 2rem;
    margin-left: 1rem;

    cursor: pointer;

    padding: .25rem;

    border-radius: 50%;

    border: 1px solid black;

    &:hover{
        background-color: rgba(200, 200, 200, .2);
    }
}
}


`

const LoaderContainer = styled.div`
 

width: 15rem;
height: 80vh;

display: flex;
justify-content: center;
align-items: center;


margin: auto;

`