import { ThreeDots } from "react-loader-spinner";

export default function Loader({ height, width }) {
    return <ThreeDots
        height={height}
        width={width}
        radius="9"
        color="#00000090"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
    />
}