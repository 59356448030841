import React, { createContext, useMemo, useState } from 'react';

const PrinterContext = createContext ( );

export function PrinterProvider({ children } ) {
  const [port, setPort] = useState (null);

  const contextValue = useMemo(() => {
    return { port, setPort };
  }, [port, setPort]);

  return (
    <PrinterContext.Provider value={contextValue}>
      {children}
    </PrinterContext.Provider>
  );
}

export default PrinterContext;
