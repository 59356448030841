import styled from "styled-components"
import Box from '@mui/material/Box';

import TextField from '@mui/material/TextField';
import { CgProfile as AccountCircle } from "react-icons/cg"
import { FiSave as CreateIcon } from "react-icons/fi"
import Fab from '@mui/material/Fab';
import Loader from '../../../Components/SideMenu/Loader';
import Swal from 'sweetalert2'
import warningWaitingCard from '../../../helpers/warnMessage';
import { HiOutlineIdentification as IdentifierIcon } from "react-icons/hi"
import { MdPhoneInTalk as PhoneIcon } from "react-icons/md"
import {FcNfcSign as NfcIcon} from "react-icons/fc"

export default function ClientInfo({name, cpf, phoneNumber, identifier}) {
    return (<Container  >
        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <AccountCircle />
            <TextField
                disabled
                id="input-name"
                variant="standard"
                label={name}
            />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <IdentifierIcon />
            <TextField
                disabled
                id="input-cpf"
                variant="standard"
                label={cpf}
            />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <PhoneIcon />
            <TextField
                disabled
                id="input-contato"
                variant="standard"
                label={phoneNumber}
            />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <NfcIcon />
            <TextField
                disabled
                id="input-contato"
                variant="standard"
                label={identifier.uid}
            />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <NfcIcon />
            <TextField
                disabled
                id="input-contato"
                variant="standard"
                label={identifier.uuid}
            />
        </Box>
    </Container>
    )
}

const Container = styled.div`
    width: 70vw;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 2rem;

    div, input {
    min-width: 115px;
    width: 20vw;
    overflow: hidden;
   
 }

&>div{
    margin-top: 1rem;
}
`