import styled from "styled-components"
import Box from '@mui/material/Box';

import TextField from '@mui/material/TextField';
import { BsCashCoin as MoneyIcon } from "react-icons/bs"
import { MdOutlineDateRange as CalendarIcon, MdOutlineDescription as DescriptionIcon, MdExpandMore as ExpandMoreIcon, MdExpandLess as ExpandLessIcon } from "react-icons/md"

import formatMonetaryValue from "../../../../helpers/formatMonetaryValue";
import dayjs from "dayjs";
import { useState } from "react";
import DetailsTable from "./DetailsTable";


export default function FinanceDetailsHeader({ startDate, endDate,  initialMoney, finalMoney, annotations, transaction }) {
    const [isShowingDescription, setIsShowingDescription] = useState(false);

    return (
    <Container  >
        <div>
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <CalendarIcon />
                <TextField
                    
                    variant="standard"
                    label="Abertura"
                    value={dayjs(startDate).format("DD/MM/YY - HH:mm")}
                    focused

                />
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <CalendarIcon />
                <TextField
                    
                    variant="standard"
                    label="Fechamento"
                    value={endDate ? dayjs(endDate).format("DD/MM/YY - HH:mm") : "-"}
                    focused

                />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'flex-end', marginTop:"1.5rem" }}>
                <MoneyIcon />
                <TextField

                     
                    variant="standard"
                    label="Valor inicial"
                    value={`R$ ${formatMonetaryValue((initialMoney * 100).toFixed(2))}`}
                    focused
                />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <MoneyIcon />
                <TextField

                   
                    variant="standard"
                    label="Valor final"
                    value={typeof(finalMoney) === "number" ? `R$ ${finalMoney < 0 ? "-" : ""} ${formatMonetaryValue((finalMoney * 100).toFixed(2))}` : "-"}
                    focused
                />
            </Box>
        </div>
        <Box sx={{ display: 'flex', alignItems: 'flex-end', width: "30vw",marginLeft:"2rem" , flexDirection:"column-reverse"   }}>
           {isShowingDescription ? <ExpandLessIcon className="clickable" onClick={() => setIsShowingDescription(false)} /> : <ExpandMoreIcon className="clickable" onClick={() => setIsShowingDescription(true)} />}
            <TextField
               
                label="Descrição"
                multiline
                rows={isShowingDescription ? 4 : 0}
                variant="filled"
                sx={{ width: "30vw" }}
                value={annotations || "-"}
                focused
            />
        </Box>

    </Container>
 
    )
}

const Container = styled.div`
    width: 100%;
   
    display: flex;
     
    flex-shrink: 0;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: .5rem;
    
    
    

    div, input {
    min-width: 115px;
     
    overflow: hidden;
   
 }

&>div:first-child{
    width: 30vw;
    display: flex;
   
    justify-content: space-around;
    flex-wrap: wrap;
     
}

.clickable{
    cursor: pointer;
}

svg{
    margin-right: .5rem;
    font-size  : 1.75rem;
}

`

 