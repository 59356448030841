import styled from "styled-components"
import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';

import IconButton from '@mui/material/IconButton';
import { MdSearch as SearchIcon, MdDeleteOutline as DeleteIcon, MdEdit as EditIcon } from "react-icons/md"
import axios from "axios";
import warningMessage from "../../helpers/warnMessage";

import formatMonetaryValue from "../../helpers/formatMonetaryValue"

import Fab from '@mui/material/Fab';
import Swal from "sweetalert2";
import ChangeStockForm from "./ChangeStockForm";
import AddItemForm from "./AddItemForm";
import { AuthContext } from "../../context/AuthContext";
 
import Loader from "../../Components/SideMenu/Loader";

export default function StockPage() {
    const [searchedItemName, setSearchedItemName] = React.useState("")
    const [stock, setStock] = React.useState([])
    const [selectedItemChangingstock, setselectedItemChangingStock ] = React.useState(null);
    const [isAddingItem, setIsAddingItem] = React.useState(false)
    const [selectedUpdatingItem, setSelectedUpdatingItem] = React.useState(null)
    const [isStockLoading, setIsStockLoading] = React.useState(true)
   
    const [isAddingItemLoading, setIsAddingItemLoading] = React.useState(false)
   

    const {user} = React.useContext(AuthContext)

    function removeDiacritics(str) {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      }

    const filteredStock = searchedItemName === "" ? stock : stock.filter(s => 
  removeDiacritics(s.name.toLowerCase())
    .includes(removeDiacritics(searchedItemName.toLowerCase()))
);

    React.useEffect(() => getCurrentStock(), [])

 
    const shoudShowAddItemForm = (isAddingItem || !!selectedUpdatingItem)
    console.log(selectedUpdatingItem);
 
    return (
        <>
            <Cover  onClick={()=> {if(!isAddingItemLoading) {setSelectedUpdatingItem(null);setselectedItemChangingStock(null); setIsAddingItem(false)}}} showModal={selectedItemChangingstock !== null || shoudShowAddItemForm}>
                {selectedItemChangingstock && <ChangeStockForm  item={selectedItemChangingstock} closeModal={()=> {setselectedItemChangingStock(null); setSelectedUpdatingItem(null)}} updateStock={getCurrentStock}/>}
                { shoudShowAddItemForm && <AddItemForm isUpdating={!!selectedUpdatingItem} isAddingItemLoading={isAddingItemLoading} setIsAddingItemLoading={setIsAddingItemLoading} closeModal={() => {setIsAddingItem(false); setSelectedUpdatingItem(null)}} selectedUpdatingItem={selectedUpdatingItem} updateStock={getCurrentStock}/>}
            </Cover>
            <Container>
                <div>
                    <Paper
                        component="form"
                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
                    >

                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Buscar"
                            value={searchedItemName}
                            onChange={e => setSearchedItemName(e.target.value)}
                            onKeyDown={(e)=>{ e.key === "Enter" && e.preventDefault()}}

                        />
                        <IconButton type="button" sx={{ p: '10px' }} onClick={()=> setSearchedItemName("")} aria-label="search">
                            x
                        </IconButton>


                    </Paper>

                    <Fab onClick={()=>setIsAddingItem(true)} color="primary" aria-label="add" sx={{ width: "35px", height: "30px", fontSize: "1.5rem", marginLeft: "2rem" }}>
                        +
                    </Fab>
                </div>

                <StockList>
                    <ListItem >
                        <div>
                            <p>Id</p>
                            <p>Nome</p>
                            <p>Categoria</p>
                            <p>Custo Compra</p>
                            <p>Outros custos</p>
                            <p>Preço venda</p>
                            <p>Qtd</p>
                        </div>


                    </ListItem>
                    {isStockLoading ? <LoaderContainer> <Loader width={"8rem"} height={"8rem"}/></LoaderContainer> : filteredStock?.map(s => <ListItem key={s.id}>
                        <div>
                            <p style={{ width:"3rem"}}>{s.id}</p>
                            <p style={{ width:"3rem"}}>{s.name}</p>
                            <p style={{ width:"4rem"}}>{s.category}</p>
                            <p style={{ width:"5rem"}}> R$ {formatMonetaryValue((s.purchaseCost * 100).toFixed(2))}</p>
                            <p style={{ width:"5rem"}}> R$ {formatMonetaryValue((s.otherCosts * 100).toFixed(2))}</p>
                            <p style={{ width:"5rem"}}> R$ {formatMonetaryValue((s.price * 100).toFixed(2))}</p>
                            <p style={{ width:"3rem"}}>{s.stockAmount || 0}</p>
                        </div>
                        <div>
                            <Fab onClick={()=> setselectedItemChangingStock(s)} color="primary" aria-label="add" sx={{ width: "35px", height: "30px", fontSize: "1.5rem", marginRight: "1rem" }}>
                                +
                            </Fab>
                            <Fab onClick={()=> setSelectedUpdatingItem(s)} color="primary" aria-label="add" sx={{ width: "35px", height: "30px", fontSize: "1.5rem", marginRight: "1rem" }}>
                               <EditIcon/>
                            </Fab>

                            <Fab onClick={() => deleteProduct(s.id, s.name)} aria-label="add" sx={{ width: "35px", height: "30px", backgroundColor: "#fa000092", fontSize: "1.5rem", ":hover": { backgroundColor: "#e00000aa" } }}>
                                <DeleteIcon />
                            </Fab>
                        </div>
                    </ListItem>)}
                </StockList>

            </Container>
        </>
    )

    function getCurrentStock() {
        const promise = axios.get(`https://${user.ip}/api/products`, {headers:{Authorization: `Bearer ${user.token}`}})
        promise.then(res => {
            setStock(res.data);
            setIsStockLoading(false)
        })
            .catch(err => {
                if (err?.response?.status === 401)
                      warningMessage("Acesso não autorizado")
                else
                     warningMessage("Erro inesperado")

                setStock([])
                setIsStockLoading(false)
            })
    }

    function deleteProduct(productId, productName) {
        Swal.fire({
            title: `Deletar ${productName}?`,
            text: "Essa ação não pode ser desfeita!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: `Sim, deletar!`,
            cancelButtonText: "Cancelar"
        }).then((result) => {
            if (result.isConfirmed) {

                const promise = axios.delete(`https://${user.ip}/api/products/${productId}`, {headers:{Authorization: `Bearer ${user.token}`}})
                promise.then(res => {
                    getCurrentStock()
                })
                    .catch(err => {
                        if (err?.response?.status === 401)
                    return warningMessage("Acesso não autorizado")

                        if (err?.response?.status !== 404)
                            return warningMessage("Erro inesperado")

                        warningMessage("Esse produto não existe")
                    })

            }
        })


    }

 


}


const Container = styled.div`
display: flex;
flex-direction: column;
position: relative;

 
align-items: center;
 

min-width: 910px;

button{
    z-index: 2;
}

& > div:first-child{
    display: flex;
    align-items: center;
    justify-content: center;

    width: 70vw;
    padding-top: 2rem;

}
`

const StockList = styled.ul`
width: 70vw;
height: 70vh;
min-width: 900px;
margin-top: 5rem;
overflow: hidden;
overflow-y: scroll;

     ::-webkit-scrollbar{
        display: none;
    
    }




border-radius: 9px;
box-shadow: 5px 5px 10px  #ababab;

padding-top: 4rem;
padding-bottom: 1rem;
 

background-color: white;

`

const ListItem = styled.li`
display: flex;
align-items: center;
justify-content: space-between;



& > div:first-child{
display: flex;
width: 80%;

height:3.5rem;
align-items: center;
justify-content: space-between;

 
padding: 1rem;
 
p{
    text-align: center;
}
p:nth-child(2){
   
    min-width: 10rem;
 
   }
p:nth-child(1){
   min-width: 3rem;
}}

p:nth-child(5){
   min-width: 3rem;
}


border-bottom: 1px solid rgba(0,0,0,.1);

&:hover{
    background-color: rgba(240,240,240);
    cursor: pointer;
}

& > div:last-child{
    width: 10rem;
    margin-right: 2rem;
}

&:first-child{
    position: absolute;
    top: 10rem;
    left: 9vw;
    background-color: white;
    z-index: 3;

    
box-shadow: 1px 2px 2px rgba(0,0,0,.1);

    @media screen and (max-width: 1600px) {
        &{
            left: 8.5vw;
        }
    }

    @media screen and (max-width: 1500px) {
        &{
            left: 8vw;
        }
    }

    @media screen and (max-width: 1400px) {
        &{
            left: 7.5vw ;
        }
    }

    @media screen and (max-width: 1300px) {
        &{
            left: 6vw;
        }
    }

    @media screen and (max-width: 1200px) {
        &{
            left: 3.5vw;
        }
    }

    @media screen and (max-width: 1100px) {
        &{
            left: 1vw;
        }
    }

    @media screen and (max-width: 100px) {
        &{
            left: 5.5vw;
        }
    }

    width: 70vw;
    min-width: 910px;

    & > div{
        width: 80%;
        p{
            text-align: center;
            font-weight: bold;
        }
    }
}
`

const Cover = styled.div`
display:  ${({showModal})=>showModal? "block" : "none"} ;
width: 100vw;
height: 100vh;

position: fixed;
left: 0;
top: 0;

z-index: 10;
background-color: rgba(0,0,0,.4);
`

const LoaderContainer = styled.div`
 

width: 15rem;
height: 50vh;

display: flex;
justify-content: center;
align-items: center;


margin: auto;

`