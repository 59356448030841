import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import formatMonetaryValue from '../../../../helpers/formatMonetaryValue';



export default function DetailsTable({transactions}){
  const nav = useNavigate();  
    transactions = transactions ? transactions : [];
    
    
    return (
        <Container>
            
        <TableContainer component={Paper} sx={{width:"70vw", height:"60vh", marginTop:"2rem"}}>
          <Table sx={{ minWidth: 500 }} aria-label="simple table" stickyHeader>
            <TableHead fixed="true">
              <TableRow>
                <TableCell>Data/hora</TableCell>
                <TableCell align="left">Tipo</TableCell>
                <TableCell align="left">Categoria</TableCell>
   
                <TableCell align="left">Valor total</TableCell>
                <TableCell align="left">Dinheiro</TableCell>
                <TableCell align="left">Pix</TableCell>
                <TableCell align="left">Cartão</TableCell>
                <TableCell align="left">Troco</TableCell>
                <TableCell align="left">Descrição</TableCell>
                
              </TableRow>
            </TableHead>
            <TableBody className='tableBody'>
              {transactions.map((row, i) => (
                <TableRow 
                  key={i}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 },backgroundColor:row.type === "Income" ?"rgba(0,200,0,.1)":"rgba(200,0,0,.1)" }}
                >
                  <TableCell component="th" scope="row">
                    {row.timestamp}
                  </TableCell>
           
                  <TableCell align="left"  sx={{color: row.type === "Income" ? "green" : "red"}}>{row.type === "Income" ? "Entrada" : "Saída"  }</TableCell>
                  <TableCell align="left">{row.category }</TableCell>
               
                  <TableCell align="left" sx={{color: row.type === "Income" ? "green" : "red", fontWeight:"bold"}}>{`R$ ${formatMonetaryValue(((row.totalValue ) *100).toFixed(2))}`}</TableCell> 
                  <TableCell align="left" >{`R$ ${formatMonetaryValue((row.moneyValue * 100 ).toFixed(2))}`}</TableCell> 
                  <TableCell align="left" >{`R$ ${formatMonetaryValue((row.pixValue *100).toFixed(2))}`}</TableCell> 
                  <TableCell align="left" >{`R$ ${formatMonetaryValue((row.cardValue *100).toFixed(2))}`}</TableCell> 
                  <TableCell align="left" >{row.category === "Venda" ? `R$ ${( (row.moneyValue - (row.totalValue - row.pixValue - row.cardValue)) < 0) ? "-" : ""} ${formatMonetaryValue(((row.moneyValue - (row.totalValue - row.pixValue - row.cardValue))*100).toFixed(2))}` : "-"}</TableCell> 
                  <TableCell align="left">{row.description || "-"}</TableCell>
                </TableRow>
              ))}
               
            </TableBody>
          </Table>
        </TableContainer>
        </Container>
      );
}

const Container = styled.div`
 
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;

 
.clickable{
    cursor: pointer;
    
    &:hover{
        background-color: rgba(0,0,0,.1);
    }
}
 
`
 