import { IoMdAddCircleOutline as AddIcon, IoMdRemoveCircleOutline as RemoveIcon } from 'react-icons/io'
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { BiLockOpen as OpenIcon, BiLock as ClosedIcon } from "react-icons/bi"
import Swal from 'sweetalert2';
import axios from 'axios';
import warningMessage from '../../../helpers/warnMessage';
import { useContext } from 'react';
import { AuthContext } from '../../../context/AuthContext';

export default function Menu({isFinanceOpen, isChangingFinance, openFinance,setRegisterFinanceType, reloadFinance, isOpeningFinance, OpenReturnItemForm, setIsFinanceLoading}) {
    const { user} =useContext(AuthContext);

    return (
    <Stack spacing={2} direction="column" width={isChangingFinance ? "20vw" : "40vw"} >
        <Button disabled={isChangingFinance || isOpeningFinance} sx={{ fontSize: "1.25rem" }} variant="contained" onClick={() => isFinanceOpen ? closeFinance() : openFinance()}>
            {isFinanceOpen ? <ClosedIcon /> : <OpenIcon />}
            {isFinanceOpen ? "Fechar" : "Abrir"} caixa
        </Button>
        <Button onClick={() => setRegisterFinanceType("income")} sx={{ fontSize: "1.25rem", bgcolor: "#00bb00", ":hover": { bgcolor: "#00aa00" } }} disabled={!isFinanceOpen || isChangingFinance} variant="contained">
            <AddIcon /> Registrar entrada
        </Button>
        <Button onClick={() => setRegisterFinanceType("outcome")} sx={{ fontSize: "1.25rem", bgcolor: "#fa0000", ":hover": { bgcolor: "#ee0000" } }} disabled={!isFinanceOpen || isChangingFinance} variant="contained"><RemoveIcon /> Registrar saída</Button>
        <Button onClick={() => OpenReturnItemForm()} sx={{ fontSize: "1.25rem", bgcolor: "#fa0000", ":hover": { bgcolor: "#ee0000" } }} disabled={!isFinanceOpen || isChangingFinance} variant="contained"><RemoveIcon /> Devolução</Button>

    </Stack>
    )

    function closeFinance(){
        Swal.fire({
            title: 'Fechar o caixa?',
            text: "Essa ação não pode ser desfeita!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, fechar o caixa!',
            cancelButtonText:"Cancelar"
          }).then((result) => {
            if (result.isConfirmed) {
                setIsFinanceLoading(true)
            const promise = axios.post(`https://${user.ip}/api/finance/close`, {}, {headers:{Authorization: `Bearer ${user.token}`}})
            promise.then(()=>{
                
                reloadFinance();
                Swal.fire(
                    'Feito!',
                    'O caixa foi fechado!',
                    'success'
                  )
            })
            .catch(e => {
                const errorStatus = e?.response?.status
                if (errorStatus === 401)
                      warningMessage("Acesso não autorizado")

                if(errorStatus === 404)
                warningMessage("Não há caixa aberto")
                
                if(errorStatus !== 404 && errorStatus !== 401)
                warningMessage("Erro inesperado")

                setIsFinanceLoading(false)
            })
            
              
            }
          })
    }
}