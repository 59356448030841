import { IoMdCloseCircleOutline as CloseIcon } from 'react-icons/io'

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { FiSave as CreateIcon } from "react-icons/fi"

import { useState } from 'react';

import styled from "styled-components"
import * as React from 'react';

import axios from "axios";
import warningMessage from "../../helpers/warnMessage";

import Fab from '@mui/material/Fab';
import Swal from "sweetalert2";
import formatMonetaryValue from '../../helpers/formatMonetaryValue';

///////////////

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { AuthContext } from '../../context/AuthContext';



////////////////////////

export default function AddItemForm({ closeModal, updateStock }) {
    const [name, setName] = useState("");
    const [price, setPrice] = useState(0);
    const [amount, setAmount] = useState(0);


    const [itemCategory, setitemCategory] = useState(["Comida", "Bebida", "Lazer", "Outro"])
    const [selectedItemCategory, setSelectedItemCategory] = useState('');

    const [description, setDescription] = useState("");

    const {user} = React.useContext(AuthContext);

    const handleChange = (event) => {
        setSelectedItemCategory(event.target.value);
    };



    return (
        <Container2 onClick={e => e.stopPropagation()} >
            <h1>Regitrar produto/serviço</h1>


            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <Box sx={{ display: 'flex', alignItems: 'flex-end', marginRight: "2rem" }}>

                    <TextField
                        id="input-value"
                        label="Nome"
                        required
                       

                        variant="standard"

                        focused
                        value={name}
                        placeholder="Ex: Caipirinha"

                        onChange={e => setName(e.target.value)}


                        sx={{ width: "15rem" }}

                    />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'flex-end', marginRight: "2rem" }}>

                    <TextField
                        id="input-value"
                        label="Preço unitário"
                        required
                        variant="standard"

                        focused
                        value={`R$ ${formatMonetaryValue(price)}`}
                        onChange={(e) => {
                            setPrice(formatEventData(e));
                        }}

                        onClick={(e) => {
                            e.target.selectionStart = `R$ ${formatMonetaryValue(price)}`.length;
                            e.target.selectionEnd = `R$ ${formatMonetaryValue(price)}`.length;

                        }}


                        sx={{ width: "7rem" }}

                    />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'flex-end', marginRight: "2rem", }}>

                    <TextField
                        id="input-value"
                        label="Quantidade"
                        required
                        variant="standard"
                        type="number"
                        focused

                        value={amount}
                        onChange={e => {
                            setAmount(+e.target.value);

                        }}


                        sx={{ width: "5rem" }}
                    />
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'flex-end', minWidth: '10rem' }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Categoria</InputLabel>
                        <Select
                             
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedItemCategory}
                            label="Categoria"
                            onChange={handleChange}
                        >
                            {itemCategory ? itemCategory.map((f, i) => (
                                <MenuItem value={i}>{f}</MenuItem>
                            )) : "Carregando.."}



                        </Select>
                    </FormControl>

                </Box>

            </Box>



            <Box sx={{ display: 'flex', alignItems: 'flex-end', marginTop: "3rem" }}>
                <Fab onClick={closeModal} variant="extended" sx={{ marginRight: "2rem" }}  >
                    {<><CloseIcon /> Cancelar</>}
                </Fab>
                <Fab variant="extended" onClick={createItem}  >
                    {<><CreateIcon /> Registrar</>}
                </Fab>
            </Box>
        </Container2>
    )

    function formatEventData(e) {
        const lastAlphanumericKey = e?.nativeEvent?.data;

        return lastAlphanumericKey === null
            ? parseInt(e.target.value.replace(",", "").replace("R", "").replace("$", "").replace(" ", "").replace(" ", ""))
            : /^[0-9]$/.test(lastAlphanumericKey)
                ? parseInt(e.target.value.replace(",", "").replace("R", "").replace("$", "").replace(" ", ""))
                : parseInt(
                    e.target.value.replace(",", "").replace("R", "").replace("$", "").replace(" ", "").replace(lastAlphanumericKey, "")
                );
    }

    function createItem() {
        if (name.length === 0)
            return warningMessage("Nome não pode ser vazio")

        if (selectedItemCategory === undefined)
            return warningMessage("Categoria não pode ser vazia")



        const mappedCategories = [
             "Food",
              "Drink",
              "recreation",
             "diverse"
        ]

        Swal.fire({
            title: `Registrar item no estoque?`,
            text: `Você está prestes a registrar ${amount} unidades de ${name}, iniciando um estoque com ${amount}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: `Confirmar!`,
            cancelButtonText: "Cancelar"
        }).then((result) => {
            if (result.isConfirmed) {

                const newitem = {
                    "name": name,
                    "price": price/100,
                    "stockAmount": amount,
                    "category": mappedCategories[selectedItemCategory]
                }

                const promise = axios.post(`https://${user.ip}/api/products`, newitem, {headers:{Authorization: `Bearer ${user.token}`}})
                promise.then(res => {
                    updateStock()
                    closeModal()
                })
                .catch(err => {
                    if (err?.response?.status === 401)
                      warningMessage("Acesso não autorizado")
                    else  
                      warningMessage("Não foi possível adicionar o produto. Erro inesperado")
                        
                
                    })

            }
        })


    }

}

const Container2 = styled.div`
display:   flex ;

position: fixed;
z-index: 20;
background-color: white;

top: 20vh;
left: 35vw;

flex-direction: column;
justify-content: center;
align-items: center;

 width: 40vw;
margin-left: 1rem;


h1{
    font-size: 1.5rem;    

    margin-bottom: 2rem;
 
 
}

 

svg{
    font-size: 1.5rem
    ;
    margin-right: .5rem;
}

border: 1px dashed black;
border-radius: 9px;

padding-top: 2rem;
padding-bottom: 2rem;

`

